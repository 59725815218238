import {
    FETCH_JOBS,
    CLEAR_JOBS,
    DELETE_JOB,
    FETCH_JOB,
    FETCH_JOB_FILES,
    DELETE_JOBS,
    FETCH_JOB_RESULTS,
    CLEAR_JOB,
    FETCH_CHILD_JOB,
    FETCH_CHILD_JOBS,
    CLEAN_CHILD_JOB,
    CLEAN_CHILD_JOBS,
    FETCH_CHILD_JOB_RESULTS,
    LOADER_JOBS_LIST,
    LOADER_CHILD_JOBS_LIST,
    LOADER_JOB_DETAILS,
} from "../actions/types";
const initialState = {
    jobs: {},
    job: {},
    childJobs: {},
    childJob: {},
    childResults: {},
    results: {},
    file: {},
    files: [],
    progress: {},
    loaderJobsList: false,
    loaderChildJobsList: false,
    loaderJobDetails: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_JOBS: {
            return { ...initialState };
        }
        case FETCH_JOBS:
            return {
                ...state,
                jobs: action.payload,
            };
        case FETCH_CHILD_JOBS:
            return {
                ...state,
                childJobs: action.payload,
                loaderChildJobsList: false,
            };
        case FETCH_CHILD_JOB:
            return {
                ...state,
                childJob: action.payload,
            };
        case CLEAN_CHILD_JOBS:
            return {
                ...state,
                chidlJobs: initialState.childJobs,
            };
        case CLEAN_CHILD_JOB:
            return {
                ...state,
                chidlJobs: initialState.childJob,
            };
        case FETCH_JOB_FILES:
            return {
                ...state,
                files: action.payload,
            };
        case FETCH_JOB:
            return {
                ...state,
                job: action.payload,
                loaderJobDetails: false,
            };
        case DELETE_JOB:
            return {
                ...state,
                job: action.payload,
                loaderJobsList: false,
            };
        case DELETE_JOBS:
            return {
                ...state,
                jobs: action.payload,
                loaderJobsList: false,
            };
        case CLEAR_JOB:
            return {
                ...state,
                job: initialState.job,
            };
        case FETCH_JOB_RESULTS:
            return {
                ...state,
                results: action.payload,
                loaderJobDetails: false,
            };
        case FETCH_CHILD_JOB_RESULTS:
            return {
                ...state,
                childResults: action.payload,
            };
        case LOADER_JOBS_LIST:
            return {
                ...state,
                loaderJobsList: true,
            };
        case LOADER_CHILD_JOBS_LIST:
            return {
                ...state,
                loaderChildJobsList: true,
            };
        case LOADER_JOB_DETAILS:
            return {
                ...state,
                loaderJobDetails: true,
            };
        default:
            return state;
    }
}
