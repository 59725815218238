import {
    FETCH_CHAPTERS,
    FETCH_CHAPTER,
    CREATE_CHAPTER,
    EDIT_CHAPTER,
    DELETE_CHAPTER,
    CLEAN_CHAPTER,
    CLEAN_CHAPTERS,
    DELETE_CHAPTERS,
    IMPORT_CHAPTERS,
    LOADER_CHAPTERS_LIST,
    LOADER_CHAPTER_FORM,
} from "../actions/types";

const initialState = {
    chapters: {},
    chapter: {},
    loaderChaptersList: false,
    loaderChaptersForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CHAPTERS:
            return {
                ...state,
                chapters: action.payload,
                loaderChaptersList: false,
            };
        case IMPORT_CHAPTERS:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersList: false,
            };
        case FETCH_CHAPTER:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersForm: false,
            };
        case CREATE_CHAPTER:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersForm: false,
            };
        case EDIT_CHAPTER:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersForm: false,
            };
        case DELETE_CHAPTER:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersList: false,
            };
        case DELETE_CHAPTERS:
            return {
                ...state,
                chapter: action.payload,
                loaderChaptersList: false,
            };
        case CLEAN_CHAPTER:
            return {
                ...state,
                chapter: initialState.chapter,
                loaderChaptersList: false,
                loaderChaptersForm: false,
            };
        case CLEAN_CHAPTERS:
            return {
                ...state,
                chapters: initialState.chapter,
                loaderChaptersForm: false,
                loaderChaptersList: false,
            };
        case LOADER_CHAPTERS_LIST:
            return {
                ...state,
                loaderChaptersList: true,
            };
        case LOADER_CHAPTER_FORM:
            return {
                ...state,
                loaderChaptersForm: true,
            };
        default:
            return state;
    }
}
