import {
    FETCH_CONTENT_CONNECTOR_CONTENT_TYPES,
    FETCH_CONTENT_CONNECTOR_CONTENT_TYPE,
    EDIT_CONTENT_CONNECTOR_CONTENT_TYPE,
    SET_READY,
    SET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM_DATA,
    SET_CONTENT_CONNECTOR_CONTENT_TYPE__FIELD_FORM_DATA,
    CLEAN_CONTENT_CONNECTOR_CONTENT_TYPE,
    CLEAN_CONTENT_CONNECTOR_CONTENT_TYPES,
    SET_LABEL_FIELD,
    REFRESH_CONTENT_CONNECTOR_CONTENT_TYPE,
    REFRESH_CONTENT_CONNECTOR_CONTENT_TYPES,
    RESET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM,
    LOADER_CCCTYPE_LIST,
    LOADER_CCCTYPE_FETCH,
    LOADER_CCCTYPE_CONFIG,
    LOADER_CCCTYPE_FORM,
} from "../actions/types";

const initialState = {
    contentTypes: {},
    contentType: {},
    editedContentType: {},
    formData: {},
    fieldData: {},
    ready: {},
    labelFieldId: {},
    loaderCCCTypeList: false,
    loaderCCCTypeFetch: false,
    loaderCCCTypeConfig: false,
    loaderCCCTypeForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAN_CONTENT_CONNECTOR_CONTENT_TYPES:
            return {
                ...initialState,
            };
        case REFRESH_CONTENT_CONNECTOR_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
                loaderCCCTypeList: false,
            };
        case FETCH_CONTENT_CONNECTOR_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
                loaderCCCTypeList: false,
            };

        case FETCH_CONTENT_CONNECTOR_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload,
                loaderCCCTypeFetch: false,
            };
        case EDIT_CONTENT_CONNECTOR_CONTENT_TYPE:
            return {
                ...state,
                editedContentType: action.payload,
                loaderCCCTypeConfig: false,
                loaderCCCTypeForm: false,
            };
        case SET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM_DATA: {
            return {
                ...state,
                formData: action.payload,
            };
        }
        case SET_READY: {
            return {
                ...state,
                ready: action.payload,
            };
        }
        case SET_CONTENT_CONNECTOR_CONTENT_TYPE__FIELD_FORM_DATA: {
            return {
                ...state,
                fieldData: action.payload,
            };
        }
        case SET_LABEL_FIELD: {
            return {
                ...state,
                labelFieldId: action.payload,
            };
        }

        case REFRESH_CONTENT_CONNECTOR_CONTENT_TYPE: {
            return {
                ...state,
                contentType: action.payload,
                loaderCCCTypeFetch: false,
            };
        }

        case RESET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM: {
            return {
                ...state,
                formData: {},
                fieldData: {},
            };
        }
        case CLEAN_CONTENT_CONNECTOR_CONTENT_TYPE: {
            return {
                ...state,
                contentType:
                    initialState.contentType,
            };
        }
        case LOADER_CCCTYPE_LIST:
            return {
                ...state,
                loaderCCCTypeList: true,
            };
        case LOADER_CCCTYPE_FETCH:
            return {
                ...state,
                loaderCCCTypeFetch: true,
            };
        case LOADER_CCCTYPE_CONFIG:
            return {
                ...state,
                loaderCCCTypeConfig: true,
            };
        case LOADER_CCCTYPE_FORM:
            return {
                ...state,
                loaderCCCTypeForm: true,
            };
        default:
            return state;
    }
}
