import {
    DROP_ITEM,
    DRAG_STATUS,
    DELETE_CBM_CELL,
    FETCH_CONTENT_BUILDER_MODELS,
    FETCH_CONTENT_TYPES,
    SET_CBM_TREE,
    CREATE_CBM,
    DELETE_CBM,
    NOTIFY_CONTENTTYPE_CHANGE,
    FETCH_CBM,
    EDIT_CBM,
    DELETE_CBM_MULTIPLE,
    DELETE_CBM_ALL,
    RESET_FLOATING_DATA,
    SAVE_FLOATING_DATA,
    TOGGLE_FLOATINGS,
    UPDATE_CBM_INSTANCE,
    RESET_CBM_INSTANCES,
    CLEAR_CBM,
    CBM_FULLSCREEN_STATUS,
    LOADER_CBM_LIST,
    LOADER_CBM_FORM,
    LOADER_CBM_FORM_CONTENT_TYPES,
} from "../actions/types";

const initialState = {
    dragStatus: false,
    item: null,
    deletedCellIndex: null,
    contentBuilderModels: {
        data: [],
    },
    contentTypes: {
        data: null,
    },
    cbmTree: {},
    createdCbm: null,
    contentType: "",
    cbm: { data: null },
    editedCbm: null,
    deletedCbm: null,
    deletedCbms: null,
    deletedAllCbms: null,
    floatingsData: {
        enableFloatings: false,
        selectedCell: {},
    },
    floatingsDataUpdated: {
        selectedCellUpdated: {},
    },
    cbmInstanceManager: {
        instanceArray: [],
        updatedCbm: null,
    },
    isFullScreen: false,
    loaderCbmList: false,
    loaderCbmForm: false,
    loaderCbmFormCCType: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DROP_ITEM:
            return {
                ...state,
                item: action.payload,
            };
        case DRAG_STATUS:
            return {
                ...state,
                dragStatus: action.payload,
            };
        case DELETE_CBM_CELL:
            return {
                ...state,
                deletedCellIndex: action.payload,
            };
        case FETCH_CONTENT_BUILDER_MODELS:
            return {
                ...state,
                contentBuilderModels: action.payload,
                loaderCbmList: false,
            };
        case FETCH_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
                loaderCbmFormCCType: false,
            };
        case SET_CBM_TREE:
            return {
                ...state,
                cbmTree: action.payload,
            };
        case CREATE_CBM:
            return {
                ...state,
                createdCbm: action.payload,
                loaderCbmForm: false,
            };
        case DELETE_CBM:
            return {
                ...state,
                deletedCbm: action.payload,
                loaderCbmList: false,
            };
        case NOTIFY_CONTENTTYPE_CHANGE:
            return {
                ...state,
                contentType: action.payload,
            };
        case FETCH_CBM:
            return {
                ...state,
                cbm: action.payload,
                loaderCbmForm: false,
            };
        case CLEAR_CBM:
            return {
                ...state,
                cbm: action.payload,
            };
        case EDIT_CBM:
            return {
                ...state,
                editedCbm: action.payload,
                loaderCbmForm: false,
            };
        case DELETE_CBM_MULTIPLE:
            return {
                ...state,
                deletedCbms: action.payload,
                loaderCbmList: false,
            };

        case DELETE_CBM_ALL:
            return {
                ...state,
                deletedAllCbms: action.payload,
                loaderCbmList: false,
            };
        case TOGGLE_FLOATINGS:
            return {
                ...state,
                floatingsData: action.payload,
            };
        case RESET_FLOATING_DATA:
            return {
                ...state,
                floatingsData: action.payload,
            };
        case SAVE_FLOATING_DATA:
            return {
                ...state,
                floatingsDataUpdated: action.payload,
            };
        case UPDATE_CBM_INSTANCE:
            return {
                ...state,
                cbmInstanceManager: action.payload,
            };

        case RESET_CBM_INSTANCES:
            return {
                ...state,
                cbmInstanceManager: action.payload,
            };
        case CBM_FULLSCREEN_STATUS:
            return {
                ...state,
                isFullScreen: action.payload,
            };
        case LOADER_CBM_LIST:
            return {
                ...state,
                loaderCbmList: true,
            };
        case LOADER_CBM_FORM:
            return {
                ...state,
                loaderCbmForm: true,
            };
        case LOADER_CBM_FORM_CONTENT_TYPES:
            return {
                ...state,
                loaderCbmFormCCType: true,
            };
        default:
            return state;
    }
}
