import {
    FETCH_TEMPLATE_LIST,
    FETCH_TEMPLATE,
    CREATE_TEMPLATE,
    EDIT_TEMPLATE,
    DELETE_TEMPLATE,
    DELETE_TEMPLATES,
    LOADER_TEMPLATE_FORM,
    LOADER_TEMPLATE_LIST,
    CLEAN_TEMPLATE,
    FETCH_TEMPLATE_SUCCESS,
    FETCH_TEMPLATE_FAILURE,
    CREATE_TEMPLATE_FAILURE,
    CREATE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    EDIT_TEMPLATE_FAILURE,
    DELETE_TEMPLATE_SUCCESS,
    EDIT_TEMPLATE_SUCCESS,
    FETCH_SHARED_TEMPLATES,
    FETCH_SHARED_TEMPLATES_SUCCESS,
    FETCH_SHARED_TEMPLATES_FAILURE,
    CLEAN_SHARED_TEMPLATES,
    SEARCH_TEMPLATES,
    FETCH_LOCAL_TEMPLATES,
    FETCH_LOCAL_TEMPLATES_SUCCESS,
    FETCH_LOCAL_TEMPLATES_FAILURE,
    CLEAN_LOCAL_TEMPLATES,
} from "../actions/types";
import produce from "immer";

const initialState = {
    templates: {},
    type: {},
    template: null,
    newTemplate: null,
    editedTemplate: null,
    deletedTemplate: null,
    deletedMultipleTemplates: null,
    loaderTemplateList: false,
    loaderTemplateForm: false,
    contentTypes: {},
};

export const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_TEMPLATE: {
            return { ...initialState };
        }
        case FETCH_TEMPLATE_LIST:
            return {
                ...state,
                templates: action.payload,
                loaderTemplateList: false,
            };
        case DELETE_TEMPLATES:
            return {
                ...state,
                deletedMultipleTemplates: action.payload,
                loaderTemplateList: false,
            };
        case LOADER_TEMPLATE_LIST:
            return {
                ...state,
                loaderTemplateList: true,
            };
        case LOADER_TEMPLATE_FORM:
            return {
                ...state,
                loaderTemplateForm: true,
            };
        default:
            return state;
    }
};

export const DEFAULT_TEMPLATE = {
    data: {},
    loading: false,
    searchLoading: false,
    error: {},
};

export const templateReducer = (
    state = { ...DEFAULT_TEMPLATE },
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case CLEAN_TEMPLATE:
                return { ...DEFAULT_TEMPLATE };

            case FETCH_TEMPLATE:
                draft.loading = true;
                break;

            case FETCH_TEMPLATE_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case FETCH_TEMPLATE_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case CREATE_TEMPLATE:
                draft.loading = true;
                break;

            case CREATE_TEMPLATE_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case CREATE_TEMPLATE_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case DELETE_TEMPLATE:
                draft.loading = true;
                break;

            case DELETE_TEMPLATE_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case DELETE_TEMPLATE_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case EDIT_TEMPLATE:
                draft.loading = true;
                break;

            case EDIT_TEMPLATE_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case EDIT_TEMPLATE_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            default:
                return state;
        }
    });

const DEFAULT_TEMPLATES = {
    data: {},
    loading: false,
    searchLoading: false,
    error: {},
};

export const sharedTemplatesReducer = (
    state = { ...DEFAULT_TEMPLATES },
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case FETCH_SHARED_TEMPLATES:
                draft.loading = true;
                break;

            case FETCH_SHARED_TEMPLATES_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case FETCH_SHARED_TEMPLATES_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case CLEAN_SHARED_TEMPLATES:
                return { ...DEFAULT_TEMPLATES };
            default:
                return state;
        }
    });

export const localTemplatesReducer = (
    state = { ...DEFAULT_TEMPLATES },
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case SEARCH_TEMPLATES:
                draft.searchLoading = true;
                break;
            case FETCH_LOCAL_TEMPLATES:
                draft.loading = true;
                break;

            case FETCH_LOCAL_TEMPLATES_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case FETCH_LOCAL_TEMPLATES_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case CLEAN_LOCAL_TEMPLATES:
                return { ...DEFAULT_TEMPLATES };
            default:
                return state;
        }
    });
