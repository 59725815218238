import {
    FETCH_SENDOUTS,
    CREATE_SENDOUT,
    EDIT_SENDOUT,
    DELETE_SENDOUT,
    DELETE_SENDOUTS,
    FETCH_SENDOUT,
    CLEAR_SENDOUTS,
    CREATE_LAYOUT,
    DELETE_ALL_SENDOUTS,
    CLEAN_LAYOUT,
    CLEAN_UP_CREATED_SENDOUT,
    CLEAN_SENDOUT,
    FETCH_SENDOUT_RECIPIENTS,
    CLEAN_SENDOUT_RECIPIENTS,
    FETCH_JOBS,
    LOADER_SENDOUTS_LIST,
    LOADER_FETCH_SENDOUT,
    LOADER_GENERATE_LAYOUT,
    LOADER_SENDOUT_FORM,
    LOADER_FETCH_SENDOUT_RECIPIENTS,
    LOADER_JOBS_FETCH,
    FETCH_STYLING_TEMPLATES,
    LOADER_STYLING_TEMPLATES_LIST,
} from "../actions/types";
const initialState = {
    requestStatus: {},
    sendouts: {},
    sendout: {},
    editedSendout: {},
    deletedSendouts: [],
    deletedSendout: {},
    deletedAllSendouts: null,
    layoutJob: {},
    recipients: {},
    jobs: {},
    loaderSendoutsList: false,
    loaderSendoutFetch: false,
    loaderGenerateLayout: false,
    loaderSendoutForm: false,
    loaderSendoutrecipientsFetch: false,
    loaderJobsFetch: false,
    loaderStylingTemplates: false,
    stylingTemplates: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_SENDOUTS: {
            return { ...initialState };
        }
        case CLEAN_SENDOUT: {
            return {
                ...state,
                sendout: {},
            };
        }
        case FETCH_SENDOUT:
            return {
                ...state,
                sendout: action.payload,
                loaderSendoutFetch: false,
            };
        case FETCH_SENDOUTS:
            return {
                ...state,
                sendouts: action.payload,
                loaderSendoutsList: false,
            };
        case DELETE_SENDOUT:
            return {
                ...state,
                deletedSendout: action.payload,
                loaderSendoutsList: false,
            };
        case DELETE_SENDOUTS:
            return {
                ...state,
                deletedSendouts: action.payload,
                loaderSendoutsList: false,
            };

        case DELETE_ALL_SENDOUTS:
            return {
                ...state,
                deletedAllSendouts: action.payload,
                loaderSendoutsList: false,
            };
        case CLEAN_UP_CREATED_SENDOUT:
            return {
                ...state,
                createdSendout: {},
            };
        case CREATE_SENDOUT:
            return {
                ...state,
                sendout: action.payload,
                loaderSendoutForm: false,
            };
        case EDIT_SENDOUT:
            return {
                ...state,
                sendout: action.payload,
                loaderSendoutForm: false,
            };

        case CREATE_LAYOUT:
            return {
                ...state,
                layoutJob: action.payload,
                loaderGenerateLayout: false,
            };
        case CLEAN_LAYOUT:
            return {
                ...state,
                layoutJob: initialState.layoutJob,
            };
        case FETCH_SENDOUT_RECIPIENTS:
            return {
                ...state,
                recipients: action.payload,
                loaderSendoutrecipientsFetch: false,
            };
        case CLEAN_SENDOUT_RECIPIENTS:
            return {
                ...state,
                recipients: initialState.recipients,
            };
        case FETCH_JOBS:
            return {
                ...state,
                jobs: action.payload,
                loaderJobsFetch: false,
            };
        case LOADER_SENDOUTS_LIST:
            return {
                ...state,
                loaderSendoutsList: true,
            };
        case LOADER_FETCH_SENDOUT:
            return {
                ...state,
                loaderSendoutFetch: true,
            };
        case LOADER_GENERATE_LAYOUT:
            return {
                ...state,
                loaderGenerateLayout: true,
            };
        case LOADER_SENDOUT_FORM:
            return {
                ...state,
                loaderSendoutForm: true,
            };
        case LOADER_FETCH_SENDOUT_RECIPIENTS:
            return {
                ...state,
                loaderSendoutrecipientsFetch: true,
            };
        case LOADER_JOBS_FETCH:
            return {
                ...state,
                loaderJobsFetch: true,
            };
        case LOADER_STYLING_TEMPLATES_LIST:
            return {
                ...state,
                loaderStylingTemplates: true,
            };
        case FETCH_STYLING_TEMPLATES:
            return {
                ...state,
                stylingTemplates: action.payload,
                loaderStylingTemplates: false,
            };
        default:
            return state;
    }
}
