import { textBlock, textBlocks } from "../actions/types";

const {
    FETCH_TEXT_BLOCKS,
    DELETE_TEXT_BLOCKS,
    EDIT_TEXT_BLOCKS,
    CREATE_TEXT_BLOCKS,
    CLEAN_TEXT_BLOCKS,
    LOADER_TEXT_BLOCKS_FORM,
    LOADER_TEXT_BLOCKS_LIST,
} = textBlocks;

const {
    FETCH_TEXT_BLOCK,
    DELETE_TEXT_BLOCK,
    EDIT_TEXT_BLOCK,
    CREATE_TEXT_BLOCK,
    CLEAN_TEXT_BLOCK,
} = textBlock;

const initialState = {
    textBlocks: {},
    textBlock: {},
    loaderTextBlocksList: false,
    loaderTextBlocksForm: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_TEXT_BLOCK:
            return {
                ...state,
                textBlock: payload,
                loaderTextBlocksForm: false,
            };
        case DELETE_TEXT_BLOCK:
            return {
                ...state,
                textBlock: payload,
                loaderTextBlocksList: false,
            };
        case EDIT_TEXT_BLOCK:
            return {
                ...state,
                textBlock: payload,
                loaderTextBlocksForm: false,
            };
        case CREATE_TEXT_BLOCK:
            return {
                ...state,
                textBlock: payload,
                loaderTextBlocksForm: false,
            };
        case CLEAN_TEXT_BLOCK:
            return {
                ...state,
                textBlock: initialState.textBlock,
                loaderTextBlocksList: false,
                loaderTextBlocksForm: false,
            };

        case FETCH_TEXT_BLOCKS:
            return {
                ...state,
                textBlocks: payload,
                loaderTextBlocksList: false,
            };
        case DELETE_TEXT_BLOCKS:
            return {
                ...state,
                textBlocks: payload,
                loaderTextBlocksList: false,
            };
        case EDIT_TEXT_BLOCKS:
            return { ...state, textBlocks: payload };
        case CREATE_TEXT_BLOCKS:
            return { ...state, textBlocks: payload };
        case CLEAN_TEXT_BLOCKS:
            return {
                ...state,
                textBlocks: initialState.textBlocks,
                loaderTextBlocksList: false,
                loaderTextBlocksForm: false,
            };
        case LOADER_TEXT_BLOCKS_FORM:
            return { ...state, loaderTextBlocksForm: true };
        case LOADER_TEXT_BLOCKS_LIST:
            return { ...state, loaderTextBlocksList: true };

        default:
            return state;
    }
};
