import React from "react";

import { useFormatMessage } from "react-intl-hooks";

import routes from "./routes";

const TheLayout = React.lazy(() => import("./core/core_containers/TheLayout"));

function Layout(props) {
    const mes = useFormatMessage();

    const nav = [
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "dashboard", defaultMessage: "Dashboard" }),
            to: "/",
            icon: "cil-home",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "sendouts", defaultMessage: "Sendouts" }),
            to: "/campaigns",
            icon: "cil-folder-open",
        },
        {
            _tag: "CSidebarNavTitle",
            name: "Datasources",
            _children: ["Datenquellen"],
        },
        {
            _tag: "CSidebarNavItem",
            name: "Content Connector",
            to: "/contentConnector",
            icon: "cil-input-power",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "recipientConnector",
                defaultMessage: "Recipient Connector",
            }),
            to: "/recipientConnector",
            icon: "cil-input-power",
        },

        {
            _tag: "CSidebarNavTitle",
            name: "Contents",
            _children: ["Contents"],
        },

        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "textBlocks",
            }),
            to: "/textBlocks",
            icon: "cil-text-square",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "indexTable",
            }),
            to: "/indexTable",
            icon: "cil-list-numbered",
        },
        {
            _tag: "CSidebarNavTitle",
            name: "CI",
            _children: ["ci"],
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "template.headline",
                defaultMessage: "Styling Template",
            }),
            to: "/template",
            icon: "cil-line-style",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "contentBuilderModel",
            }),
            to: "/contentBuilderModel",
            icon: "cil-columns",
        },

        {
            _tag: "CSidebarNavItem",
            name: "CSS",
            to: "/css",
            icon: "cib-css3",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({
                id: "outputManagement",
                defaultMessage: "Output Management",
            }),
            to: "/outputManagement",
            icon: "cil-settings",
        },
        {
            _tag: "CSidebarNavItem",
            name: "Layout Generator",
            to: "/layoutGeneratorConfig",
            icon: "cil-equalizer",
        },

        {
            _tag: "CSidebarNavTitle",
            name: "adminstration",
            _children: ["administration"],
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "workspaces", defaultMessage: "Workspaces" }),
            to: "/workspaces",
            icon: "cil-user",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "users", defaultMessage: "Users" }),
            to: "/users",
            icon: "cil-people",
        },
    ];

    return <TheLayout routes={routes} nav={nav} {...props} />;
}

export default Layout;
