import { combineReducers } from "redux";

import campaignReducer from "./campaignReducer";
import sendoutReducer from "./sendoutReducer";
import chapterReducer from "./chapterReducer";
import recipientReducer from "./recipientReducer";
import contentSelectorReducer from "./contentSelectorReducer";
import sectionsReducer from "./sectionReducer";
import contentRefReducer from "./contentRefsReducer";
import jobReducer from "./jobReducer";
import outputReducer from "./outputReducer";
import contentBuilderModelReducer from "./contentBuilderModelReducer";
import wizardReducer from "./wizardReducer";
import cssReducer from "./cssReducer";
import coreReducer from "../../core/core_reducer";
import contentConnectorReducer from "./contentConnectorReducer";
import contentTypeReducer from "./contentTypeReducer";
import textBlockReducer from "./textBlockReducer";
import layoutGeneratorConfigReducer from "./layoutGeneratorConfigReducer";
import indexTableReducer from "./indexTableReducer";
import recipientConnectorReducer from "./recipientConnectorReducer";
import recipientTypeReducer from "./recipientTypeReducer";
import {
    templatesReducer,
    templateReducer,
    sharedTemplatesReducer,
    localTemplatesReducer,
} from "./templateReducer";
import {
    previewImagesLengthReducer,
    previewImageReducer,
} from "./previewImageReducer";

import { fontTypesReducer } from "./templateStylingReducer";
import {
    assetsManagerReducer,
    assetManagerReducer,
} from "./assetManagerReducer";

export default combineReducers({
    campaigns: campaignReducer,
    sendouts: sendoutReducer,
    chapters: chapterReducer,
    recipients: recipientReducer,
    sections: sectionsReducer,
    contentRefs: contentRefReducer,
    jobs: jobReducer,
    output: outputReducer,
    contentSelector: contentSelectorReducer,
    contentBuilderModel: contentBuilderModelReducer,
    wizard: wizardReducer,
    contentConnector: contentConnectorReducer,
    contentType: contentTypeReducer,
    css: cssReducer,
    textBlocks: textBlockReducer,
    layoutGeneratorConfig: layoutGeneratorConfigReducer,
    indexTable: indexTableReducer,
    recipientConnector: recipientConnectorReducer,
    recipientType: recipientTypeReducer,
    templates: templatesReducer,
    sharedTemplates: sharedTemplatesReducer,
    localTemplates: localTemplatesReducer,
    template: templateReducer,
    previewImage: previewImageReducer,
    previewImagesLength: previewImagesLengthReducer,
    fontTypes: fontTypesReducer,
    assetsManager: assetsManagerReducer,
    assetManager: assetManagerReducer,
    ...coreReducer,
});
