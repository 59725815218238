import {
    SET_CSS,
    POST_CSS,
    CLEAN_CSS,
    GET_CSS_FILE,
    FETCH_CSS_FILES,
    DELTE_ALL_CSS_FILES,
    DELTE_CSS_FILE,
    DELTE_CSS_FILES,
    PATCH_CSS_FILE,
    LOADER_CSS_FILES_LIST,
    LOADER_CSS_FORM,
} from "../actions/types";

const initialState = {
    currentFile: null,
    files: {},
    file: {},
    loaderCssFilesList: false,
    loaderCssForm: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAN_CSS:
            return {
                ...initialState,
            };
        case SET_CSS:
            return {
                ...state,
                currentFIle: payload,
            };
        case POST_CSS:
            return {
                ...state,
                file: payload,
                loaderCssForm: false,
            };
        case DELTE_ALL_CSS_FILES:
            return {
                ...state,
                cssResponse: payload,
                loaderCssFilesList: false,
            };
        case DELTE_CSS_FILE:
            return {
                ...state,
                cssResponse: payload,
                loaderCssFilesList: false,
            };
        case PATCH_CSS_FILE:
            return {
                ...state,
                cssResponse: payload,
                loaderCssForm: false,
            };
        case DELTE_CSS_FILES:
            return {
                ...state,
                cssResponse: payload,
                loaderCssFilesList: false,
            };
        case GET_CSS_FILE:
            return {
                ...state,
                file: payload,
                loaderCssForm: false,
            };
        case FETCH_CSS_FILES:
            return {
                ...state,
                files: payload,
                loaderCssFilesList: false,
            };
        case LOADER_CSS_FILES_LIST:
            return {
                ...state,
                loaderCssFilesList: true,
            };
        case LOADER_CSS_FORM:
            return {
                ...state,
                loaderCssForm: true,
            };
        default:
            return state;
    }
};
