import produce from "immer";
import {
    CLEAN_PREVIEW_IMAGE,
    CLEAN_PREVIEW_IMAGES_LENGTH,
    FETCH_PREVIEW_IMAGE,
    FETCH_PREVIEW_IMAGES_LENGTH,
    FETCH_PREVIEW_IMAGES_LENGTH_FAILURE,
    FETCH_PREVIEW_IMAGES_LENGTH_SUCCESS,
    FETCH_PREVIEW_IMAGE_FAILURE,
    FETCH_PREVIEW_IMAGE_SUCCESS,
} from "../actions/types";

const DEFAULT_IMAGES = {
    data: {},
    loading: false,
    error: {},
};

const DEFAULT_IMAGES_LENGTH = {
    data: {},
    loading: false,
    error: {},
};

export const initialStatePreviewImagesLength = {
    ...DEFAULT_IMAGES_LENGTH,
};

export const previewImagesLengthReducer = (
    state = initialStatePreviewImagesLength,
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (
            type // @todo clean
        ) {
            case CLEAN_PREVIEW_IMAGES_LENGTH:
                return { ...DEFAULT_IMAGES_LENGTH };
            case FETCH_PREVIEW_IMAGES_LENGTH:
                draft.loading = true;
                break;
            case FETCH_PREVIEW_IMAGES_LENGTH_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case FETCH_PREVIEW_IMAGES_LENGTH_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            default:
                return state;
        }
    });

export const initialStatePreviewImages = {
    ...DEFAULT_IMAGES,
};

export const previewImageReducer = (
    state = initialStatePreviewImages,
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (
            type // @todo clean
        ) {
            case CLEAN_PREVIEW_IMAGE:
                return { ...DEFAULT_IMAGES };
            case FETCH_PREVIEW_IMAGE:
                draft.loading = true;
                break;
            case FETCH_PREVIEW_IMAGE_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case FETCH_PREVIEW_IMAGE_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            default:
                return state;
        }
    });
