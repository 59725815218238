import {
    FETCH_LAYOUT_GENERATOR_CONFIG_LIST,
    FETCH_LAYOUT_GENERATOR_CONFIG,
    FETCH_LAYOUT_GENERATOR_TYPES,
    CREATE_LAYOUT_GENERATOR_CONFIG,
    EDIT_LAYOUT_GENERATOR_CONFIG,
    DELETE_LAYOUT_GENERATOR_CONFIG,
    DELETE_LAYOUT_GENERATOR_CONFIGS,
    LOADER_LAYOUT_GENERATOR_CONFIG_FORM,
    LOADER_LAYOUT_GENERATOR_CONFIG_LIST,
} from "../actions/types";

const initialState = {
    configs: {},
    type: {},
    layoutGeneratorConfig: null,
    newLayoutGeneratorConfig: null,
    editedLayoutGeneratorConfig: null,
    deletedLayoutGeneratorConfig: null,
    deletedMultipleLayoutGeneratorConfigs: null,
    loaderLayoutGeneratorConfigList: false,
    loaderLayoutGeneratorConfigForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_LAYOUT_GENERATOR_CONFIG_LIST:
            return {
                ...state,
                configs: action.payload,
                loaderLayoutGeneratorConfigList: false,
            };
        case FETCH_LAYOUT_GENERATOR_CONFIG:
            return {
                ...state,
                layoutGeneratorConfig: action.payload,
                loaderLayoutGeneratorConfigForm: false,
            };
        case FETCH_LAYOUT_GENERATOR_TYPES:
            return {
                ...state,
                types: action.payload,
                loaderLayoutGeneratorConfigForm: false,
            };
        case CREATE_LAYOUT_GENERATOR_CONFIG:
            return {
                ...state,
                newLayoutGeneratorConfig: action.payload,
                loaderLayoutGeneratorConfigForm: false,
            };
        case EDIT_LAYOUT_GENERATOR_CONFIG:
            return {
                ...state,
                editedLayoutGeneratorConfig: action.payload,
                loaderLayoutGeneratorConfigForm: false,
            };
        case DELETE_LAYOUT_GENERATOR_CONFIG:
            return {
                ...state,
                deletedLayoutGeneratorConfig: action.payload,
                loaderLayoutGeneratorConfigList: false,
            };
        case DELETE_LAYOUT_GENERATOR_CONFIGS:
            return {
                ...state,
                deletedMultipleLayoutGeneratorConfigs: action.payload,
                loaderLayoutGeneratorConfigList: false,
            };
        case LOADER_LAYOUT_GENERATOR_CONFIG_LIST:
            return {
                ...state,
                loaderLayoutGeneratorConfigList: true,
            };
        case LOADER_LAYOUT_GENERATOR_CONFIG_FORM:
            return {
                ...state,
                loaderLayoutGeneratorConfigForm: true,
            };
        default:
            return state;
    }
}
