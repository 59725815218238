import {
    FETCH_CONTENT_CONNECTOR_CONFIGS,
    FETCH_CONTENT_CONNECTOR_CONFIG,
    DELETE_CONTENT_CONNECTOR_CONFIGS,
    DELETE_CONTENT_CONNECTOR_CONFIG,
    DELETE_ALL_CONTENT_CONNECTOR_CONFIGS,
    FETCH_CONTENT_CONNECTOR_TYPES,
    CREATE_CONTENT_CONNECTOR_CONFIGS,
    EDIT_CONTENT_CONNECTOR_CONFIG,
    FETCH_CONTENT_CONNECTOR_CONTENTS,
    FETCH_CONTENT_CONNECTOR_CONTENT,
    CLEAN_CONTENT_CONNECTOR_CONTENT,
    CONTENT_CONNECTOR_INITIALISATION,
    CONTENT_CONNECTOR_AUTH_ERROR,
    LOADER_CONTENT_CONNECTOR_LIST,
    LOADER_CONTENT_CONNECTOR_TYPES_FETCH,
    LOADER_CONTENT_CONNECTOR_INITIALISATION,
    LOADER_CONTENT_CONNECTOR_CONTENTS_LIST,
    LOADER_FETCH_CONTENT_CONNECTOR_CONTENT,
} from "../actions/types";

const initialState = {
    content: {},
    contentConnectorConfigs: {},
    connectorTypes: {},
    contentConnectorConfig: {},
    createdContentConnectorConfig: {},
    editedContentConnectorConfig: {},
    deletedContentConnectorConfigs: [],
    deletedContentConnectorConfig: {},
    deletedAllContentConnectorConfigs: null,
    error: null,
    contents: {},
    loaderCCList: false,
    loaderCCTypes: false,
    loaderCCInit: false,
    loaderCCCList: false,
    loaderCCCFetch: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                contentConnectorConfigs: action.payload,
                loaderCCList: false,
            };
        case FETCH_CONTENT_CONNECTOR_CONFIG:
            return {
                ...state,
                contentConnectorConfig: action.payload,
            };
        case CREATE_CONTENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                createdContentConnectorConfig: action.payload,
            };
        case EDIT_CONTENT_CONNECTOR_CONFIG:
            return {
                ...state,
                editedContentConnectorConfig: action.payload,
            };
        case DELETE_CONTENT_CONNECTOR_CONFIG:
            return {
                ...state,
                deletedContentConnectorConfig: action.payload,
                loaderCCList: false,
            };
        case DELETE_CONTENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                deletedContentConnectorConfigs: action.payload,
                loaderCCList: false,
            };

        case DELETE_ALL_CONTENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                deletedAllContentConnectorConfigs: action.payload,
                loaderCCList: false,
            };
        case FETCH_CONTENT_CONNECTOR_TYPES:
            return {
                ...state,
                connectorTypes: action.payload,
                loaderCCTypes: false,
            };
        case FETCH_CONTENT_CONNECTOR_CONTENTS:
            return {
                ...state,
                contents: action.payload,
                loaderCCCList: false,
            };
        case FETCH_CONTENT_CONNECTOR_CONTENT:
            return {
                ...state,
                content: action.payload,
                loaderCCCFetch: false,
            };
        case CLEAN_CONTENT_CONNECTOR_CONTENT:
            return {
                ...state,
                content: initialState.content,
            };
        case CONTENT_CONNECTOR_INITIALISATION:
            return {
                ...state,
                contentConnectorInitialised: action.payload,
                loaderCCInit: false,
            };
        case CONTENT_CONNECTOR_AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case LOADER_CONTENT_CONNECTOR_LIST:
            return {
                ...state,
                loaderCCList: true,
            };
        case LOADER_CONTENT_CONNECTOR_TYPES_FETCH:
            return {
                ...state,
                loaderCCTypes: true,
            };
        case LOADER_CONTENT_CONNECTOR_INITIALISATION:
            return {
                ...state,
                loaderCCInit: true,
            };
        case LOADER_CONTENT_CONNECTOR_CONTENTS_LIST:
            return {
                ...state,
                loaderCCCList: true,
            };
        case LOADER_FETCH_CONTENT_CONNECTOR_CONTENT:
            return {
                ...state,
                loaderCCCFetch: true,
            };
        default:
            return state;
    }
}
