import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

import { IntlProvider } from "react-intl";
import ReactResizeDetector from "react-resize-detector";
import history from "./core/core_services/history";
import core_translations from "./core/core_i18n/index";
import cm_translations from "./cm/i18n/index";

import { setWindowSize } from "./core/core_actions/localeActions";
import FoldCube from "./core/core_components/Spinner/FoldCube";
import "font-awesome/scss/font-awesome.scss";
import { setLocale } from "./core/core_actions/localeActions";
import { useGetCurrentWorkspace } from "./core/core_components/Workspaces/Hooks/WorkspaceHooks";

import withServices from "./core/core_services/Services";
import Layout from "./Layout";
import CommonService from "./core/core_services/commonService";

import { CCard, CCardBody, CContainer } from "@coreui/react";
// Containers
const Login = React.lazy(() => import("./core/core_views/pages/Login"));

const App = (props) => {
    const lang = useSelector((state) => state.locale.lang);
    const toasterInformation = useSelector((state) => state.toaster.toaster);
    const loaderWorkspaceSelection = useSelector(
        (state) => state.workspaces.loaderWorkspaceSelection
    );
    const dispatch = useDispatch();
    const storedLang = window.localStorage.getItem("lang");
    const currentWorkspace = useGetCurrentWorkspace();
    const [toaster, setToaster] = useState("");

    useEffect(() => {
        setToaster(
            CommonService.toaster(
                toasterInformation.type,
                toasterInformation.value
            )
        );
    }, [toasterInformation]);
    useEffect(() => {
        if (storedLang !== null) {
            dispatch(setLocale(storedLang));
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        sessionStorage.setItem(
            "pagination",
            JSON.stringify({
                itemsPerPage: {},
            })
        );
    }, []);

    const handleToaster = (type, value, id, coolDown) => {
        setToaster(CommonService.toaster(type, value, id, coolDown));
    };

    const getTranslations = () => {
        let translations = {};
        translations.en = {
            ...core_translations["en"],
            ...cm_translations["en"],
        };
        translations.de = {
            ...core_translations["de"],
            ...cm_translations["de"],
        };
        return translations;
    };

    const translations = getTranslations();

    const serveLoader = () => {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    zIndex: 9999,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
                data-cy="loadingIcon"
            >
                <span
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        marginTop: "-50px",
                        marginLeft: "-100px",
                    }}
                >
                    <FoldCube />
                </span>
            </div>
        );
    };
    return (
        <IntlProvider locale={lang} messages={translations[lang]}>
            <HashRouter history={history}>
                <React.Suspense
                    fallback={
                        <CContainer className="mt-5">
                            <CCard>
                                <CCardBody>
                                    <div className="loadingScreen ">
                                        <div className="mb-3">Redirect</div>
                                        <FoldCube />
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CContainer>
                    }
                >
                    {loaderWorkspaceSelection ? serveLoader() : null}
                    {toaster}
                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        onResize={() =>
                            dispatch(setWindowSize(window.innerWidth))
                        }
                    />
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            name="Login Page"
                            render={(props) => <Login {...props} />}
                        />
                        <Route
                            path="/"
                            name="Home"
                            render={(props) => (
                                <Layout
                                    toaster={handleToaster}
                                    {...props}
                                    currentWorkspace={currentWorkspace}
                                />
                            )}
                        />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        </IntlProvider>
    );
};

export default withServices(App);
