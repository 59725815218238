import {
    FETCH_CONTENT_REFS,
    FETCH_CONTENT_REF,
    EDIT_CONTENT_REF,
    CLEAN_CONTENT_REF,
    LOADER_CONTENT_REFS_FORM,
    LOADER_CONTENT_REFS_LIST,
    DELETE_CONTENT_REFS,
} from "../actions/types";

const initialState = {
    refs: {},
    ref: {},
    loaderContentRefsList: false,
    loaderContentRefsForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTENT_REFS:
            return {
                ...state,
                refs: action.payload,
                loaderContentRefsList: false,
            };
        case FETCH_CONTENT_REF:
            return {
                ...state,
                ref: action.payload,
                loaderContentRefsForm: false,
            };
        case EDIT_CONTENT_REF:
            return {
                ...state,
                ref: action.payload,
                loaderContentRefsForm: false,
            };
        case CLEAN_CONTENT_REF:
            return {
                ...state,
                ref: initialState.ref,
            };
        case DELETE_CONTENT_REFS:
            return { ...state, loaderContentRefsList: false };
        case LOADER_CONTENT_REFS_FORM:
            return {
                ...state,
                loaderContentRefsForm: true,
            };
        case LOADER_CONTENT_REFS_LIST:
            return {
                ...state,
                loaderContentRefsList: true,
            };
        default:
            return state;
    }
}
