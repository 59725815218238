import produce from "immer";
import {
    FETCH_FONT_TYPES,
    FETCH_FONT_TYPES_FAILURE,
    FETCH_FONT_TYPES_SUCCESS,
} from "../actions/types";

export const DEFAULT_FONT_TYPES = {
    data: {},
    loading: false,
    error: {},
};

export const initialFontTypes = {
    ...DEFAULT_FONT_TYPES,
};

export const fontTypesReducer = (
    state = initialFontTypes,
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case FETCH_FONT_TYPES:
                draft.loading = true;
                break;

            case FETCH_FONT_TYPES_SUCCESS || FETCH_FONT_TYPES_FAILURE:
                draft.data = payload;
                draft.loading = false;
                draft.error = error;
                break;
            default:
                return state;
        }
    });
