/* eslint-disable */
import React from "react";
// eslint-disable-next-line
import core_routes from "./core/core_routes";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

const Dashboard = React.lazy(() =>
    import("./cm/components/Dashboard/Dashboard")
);
const Campaigns = React.lazy(() => import("./cm/components/Campaigns/"));
const SendoutFormContainer = React.lazy(() =>
    import("./cm/components/SendoutForm/SendoutFormContainer")
);

const TriggerLayoutJob = React.lazy(() =>
    import("./cm/components/Jobs/TriggerLayoutJob")
);
const ContentConnector = React.lazy(() =>
    import("./cm/components/ContentConnector/ContentConnector")
);
const ConnectorAuth = React.lazy(() =>
    import("./cm/components/ContentConnector/ConnectorAuth")
);
const ContentConnectorSelection = React.lazy(() =>
    import("./cm/components/ContentConnector/ContentConnectorSelection")
);

const ContentType = React.lazy(() =>
    import("./cm/components/ContentConnector/ContentType/ContentType")
);
const ContentTypeFormContainer = React.lazy(() =>
    import(
        "./cm/components/ContentConnector/ContentType/ContentTypeFormContainer"
    )
);
const CssList = React.lazy(() => import("./cm/components/Css/CssList"));
const CssForm = React.lazy(() => import("./cm/components/Css/CssForm"));

const Job = React.lazy(() => import("./cm/components/Jobs/Job"));

const CatalogWizard = React.lazy(() =>
    import("./cm/components/Wizard/CatalogWizardContainer")
);
const LetterWizard = React.lazy(() =>
    import("./cm/components/Wizard/LetterWizardContainer")
);
const SendoutWizardContainer = React.lazy(() =>
    import("./cm/components/Wizard/SendoutWizardContainer")
);
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
/**
 * Content Builder Model Imports
 */

const ContentBuilderModel = React.lazy(() =>
    import("./cm/components/ContentBuilderModel/ContentBuilderModel")
);
const ContentBuilderModelForm = React.lazy(() =>
    import(
        "./cm/components/ContentBuilderModel/ContentBuilderModelForm/ContentBuilderModelForm"
    )
);

const RecipientType = React.lazy(() =>
    import("./cm/components/RecipientConnector/RecipientType/RecipientType")
);

const TextBlocks = React.lazy(() =>
    import("./cm/components/Textblock/TextBlocks")
);
const TextBlockContainer = React.lazy(() =>
    import("./cm/components/Textblock/TextBlockContainer")
);

/**
 * Output Management Imports
 */
const Output = React.lazy(() => import("./cm/components/Output/Output"));
const OutputForm = React.lazy(() =>
    import("./cm/components/Output/OutputForm/OutputForm")
);

/**
 * Layout Generator Config Imports
 */
const LayoutGeneratorConfig = React.lazy(() =>
    import("./cm/components/LayoutGeneratorConfig/LayoutGeneratorConfig")
);
const LayoutGeneratorConfigForm = React.lazy(() =>
    import(
        "./cm/components/LayoutGeneratorConfig/LayoutGeneratorConfigForm/LayoutGeneratorConfigForm"
    )
);

/**
 * Index Table Imports
 */
const IndexTable = React.lazy(() =>
    import("./cm/components/IndexTable/IndexTable")
);
const IndexTableForm = React.lazy(() =>
    import("./cm/components/IndexTable/IndexTableForm/IndexTableForm")
);

/**
 * Recipient Connector Imports
 */

const RecipientConnector = React.lazy(() =>
    import("./cm/components/RecipientConnector/RecipientConnector")
);

const RecipientConnectorSelection = React.lazy(() =>
    import("./cm/components/RecipientConnector/RecipientConnectorSelection")
);

/**
 * Styling Template Imports
 */
const Template = React.lazy(() => import("./cm/components/Template/Template"));
const TemplateForm = React.lazy(() =>
    import("./cm/components/Template/TemplateComponents/TemplateForm")
);

const routes = [
    { path: "/", exact: true, name: "Home", component: Dashboard },
    {
        path: "/campaigns",
        exact: true,
        name: <FormattedMessage id="sendouts" defaultMessage="Campaigns" />,
        component: Campaigns,
    },
    {
        path: "/campaigns/create",
        exact: true,
        name: <FormattedMessage id="sendout" defaultMessage="Campaign Form" />,
        component: SendoutFormContainer,
    },
    {
        path: "/campaigns/edit/:id",
        exact: true,
        name: <FormattedMessage id="sendout" defaultMessage="Campaign Form" />,
        component: SendoutFormContainer,
    },
    {
        path: "/campaigns/edit/:id",
        exact: true,
        name: <FormattedMessage id="sendout" defaultMessage="Campaign Form" />,
        component: SendoutFormContainer,
    },
    {
        path: "/campaigns/:id/job",
        exact: true,
        name: (
            <FormattedMessage
                id="sendout.form"
                defaultMessage="Campaign Form"
            />
        ),
        component: SendoutFormContainer,
    },
    {
        path: "/triggerJob/:id",
        exact: true,
        name: <FormattedMessage id="sendout" defaultMessage="Campaign Form" />,
        component: TriggerLayoutJob,
    },
    {
        path: "/campaigns/:id/layoutJob",
        exact: true,
        name: (
            <FormattedMessage
                id="layoutJob.create"
                defaultMessage="Layout Job"
            />
        ),
        component: Job,
    },
    {
        path: "/contentConnector",
        exact: true,
        name: (
            <FormattedMessage
                id="contentConnector"
                defaultMessage="Content Connector"
            />
        ),
        component: ContentConnector,
    },
    {
        path: "/contentConnectorAuth",
        exact: true,
        name: (
            <FormattedMessage
                id="contentConnector"
                defaultMessage="Content Connector"
            />
        ),
        component: ConnectorAuth,
    },
    {
        path: "/contentConnector/select",
        exact: true,
        name: (
            <FormattedMessage
                id="contentConnectorCreate"
                defaultMessage="Create Content Connector"
            />
        ),
        component: ContentConnectorSelection,
    },
    {
        path: "/contentConnector/:CCTypeId/contentType",
        exact: true,
        name: (
            <FormattedMessage
                id="contentType"
                defaultMessage="Content Connector Content Type"
            />
        ),
        component: ContentType,
    },
    {
        path: "/contentConnector/:CCTypeId/contentType/edit/:CCCTypeId",
        exact: true,
        name: (
            <FormattedMessage
                id="ContentTypeEdit"
                defaultMessage="Edit Content Connector Content Type"
            />
        ),
        component: ContentTypeFormContainer,
    },

    /**
     * ContentBuilderModel Routes
     */

    {
        path: "/contentBuilderModel",
        exact: true,
        name: (
            <FormattedMessage
                id="ContentBuilderModel"
                defaultMessage="Content Builder Model"
            />
        ),
        component: ContentBuilderModel,
    },
    {
        path: "/contentBuilderModel/create",
        exact: true,
        name: (
            <FormattedMessage
                id="ContentBuilderModelCreate"
                defaultMessage="Create Content Builder Model"
            />
        ),
        component: ContentBuilderModelForm,
    },
    {
        path: "/contentBuilderModel/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="contentBuilderModelEdit"
                defaultMessage="Edit Content Builder Model"
            />
        ),
        component: ContentBuilderModelForm,
    },
    {
        path: "/css",
        exact: true,
        name: "CSS",
        component: CssList,
    },
    {
        path: "/css/create",
        exact: true,
        name: "CSS-Form",
        component: CssForm,
    },
    {
        path: "/css/edit/:id",
        exact: true,
        name: "CSS-Form",
        component: CssForm,
    },
    {
        path: "/textBlocks/create",
        exact: true,
        name: <FormattedMessage id="textBlocks.create" />,
        component: TextBlockContainer,
    },
    {
        path: "/textBlocks/edit/:id",
        exact: true,
        name: <FormattedMessage id="textBlocks.edit" />,
        component: TextBlockContainer,
    },
    {
        path: "/textBlocks",
        exact: true,
        name: <FormattedMessage id="textBlocks" />,
        component: TextBlocks,
    },
    {
        path: "/campaigns/wizard/:id",
        exact: true,
        name: "Campaing - Wizard",
        component: SendoutWizardContainer,
    },
    {
        path: "/catalog",
        exact: true,
        name: "Catalog",
        component: CatalogWizard,
    },
    {
        path: "/letter",
        exact: true,
        name: "Letter",
        component: LetterWizard,
    },
    {
        path: "/outputManagement",
        exact: true,
        name: (
            <FormattedMessage
                id="outputManagement"
                defaultMessage="Output Management"
            />
        ),
        component: Output,
    },
    {
        path: "/outputManagement/create",
        exact: true,
        name: (
            <FormattedMessage
                id="outputManagement.create"
                defaultMessage="Create Output Config"
            />
        ),
        component: OutputForm,
    },
    {
        path: "/outputManagement/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="outputManagement.edit"
                defaultMessage="Edit Output Config"
            />
        ),
        component: OutputForm,
    },
    {
        path: "/layoutGeneratorConfig",
        exact: true,
        name: (
            <FormattedMessage
                id="layoutGenerator"
                defaultMessage="Layout Generator Config"
            />
        ),
        component: LayoutGeneratorConfig,
    },
    {
        path: "/layoutGeneratorConfig/create",
        exact: true,
        name: (
            <FormattedMessage
                id="layoutGeneratorConfig.create"
                defaultMessage="Create Layout Generator Config"
            />
        ),
        component: LayoutGeneratorConfigForm,
    },
    {
        path: "/layoutGeneratorConfig/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="layoutGeneratorConfig.edit"
                defaultMessage="Edit Layout Generator Config"
            />
        ),
        component: LayoutGeneratorConfigForm,
    },
    {
        path: "/indexTable",
        exact: true,
        name: <FormattedMessage id="indexTable" defaultMessage="Index Table" />,
        component: IndexTable,
    },
    {
        path: "/indexTable/create",
        exact: true,
        name: (
            <FormattedMessage
                id="indexTable.create"
                defaultMessage="Create Index Table"
            />
        ),
        component: IndexTableForm,
    },
    {
        path: "/indexTable/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="indexTable.edit"
                defaultMessage="Edit Index Table"
            />
        ),
        component: IndexTableForm,
    },

    /**
     * Recipient Connector Routing
     */

    {
        path: "/recipientConnector",
        exact: true,
        name: (
            <FormattedMessage
                id="recipientConnector"
                defaultMessage="Recipient Connector"
            />
        ),
        component: RecipientConnector,
    },

    {
        path: "/recipientConnector/select",
        exact: true,
        name: (
            <FormattedMessage
                id="recipientConnectorCreate"
                defaultMessage="Create Recipient Connector"
            />
        ),
        component: RecipientConnectorSelection,
    },
    {
        path: "/recipientConnector/:RCTypeId/recipientType",
        exact: true,
        name: (
            <FormattedMessage
                id="recipientConnectorView"
                defaultMessage="Recipients"
            />
        ),
        component: RecipientType,
    },

    /** Styling template routes */
    {
        path: "/template",
        exact: true,
        name: (
            <FormattedMessage
                id="stylingTemplate.headline"
                defaultMessage="Styling Template"
            />
        ),
        component: Template,
    },
    {
        path: "/template/create",
        exact: true,
        name: (
            <FormattedMessage
                id="stylingTemplate.create"
                defaultMessage="Create Styling Template"
            />
        ),
        component: TemplateForm,
    },
    {
        path: "/template/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="stylingTemplate.edit"
                defaultMessage="Edit Styling Template"
            />
        ),
        component: TemplateForm,
    },
];

const combinedRoutes = _.concat(core_routes, routes);
export default combinedRoutes;
