import {
    FETCH_RECIPIENTS,
    FETCH_RECIPIENT,
    DELETE_RECIPIENT,
    DELETE_RECIPIENTS,
    DELETE_ALL_RECIPIENTS,
    REMOVE_DELETED_RECIPIENT,
    CREATE_RECIPIENT,
    UPDATE_RECIPIENT,
    SET_IMPORTED_RECIPIENT_FILE,
    REMOVE_IMPORTED_RECIPIENT_FILE,
    UPLOAD_RECIPIENTS,
    CLEAN_IMPORTED_RECIPIENTS,
    LOADER_RECIPIENTS,
} from "../actions/types";

const initialState = {
    uploadedRecipients: {},
    createdRecipient: {},
    deleteRecipient: -1,
    deleteRecipients: -1,
    deleteAllRecipients: null,
    updatedRecipient: {},
    recipients: [],
    recipient: {},
    importedRecipients: null,
    loaderRecipients: false,
};
export default function localeReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_RECIPIENT:
            return {
                ...state,
                createdRecipient: action.payload,
            };
        case UPLOAD_RECIPIENTS:
            return {
                ...state,
                uploadedRecipients: action.payload,
            };
        case UPDATE_RECIPIENT:
            return {
                ...state,
                updatedRecipient: action.payload,
            };
        case FETCH_RECIPIENT:
            return {
                ...state,
                recipient: action.payload,
            };
        case FETCH_RECIPIENTS:
            return {
                ...state,
                recipients: action.payload,
                loaderRecipients: false,
            };
        case DELETE_RECIPIENT:
            return {
                ...state,
                deleteRecipient: action.payload,
            };
        case DELETE_RECIPIENTS:
            return {
                ...state,
                deleteRecipients: action.payload,
            };
        case DELETE_ALL_RECIPIENTS:
            return {
                ...state,
                deleteAllRecipients: action.payload,
            };
        case REMOVE_DELETED_RECIPIENT:
            return {
                ...state,
                deleteRecipient: -1,
            };
        case SET_IMPORTED_RECIPIENT_FILE:
            return {
                ...state,
                importedRecipients: action.payload,
            };
        case REMOVE_IMPORTED_RECIPIENT_FILE:
            return {
                ...state,
                importedRecipients: null,
            };
        case CLEAN_IMPORTED_RECIPIENTS:
            return {
                ...state,
                recipients: [],
            };
        case LOADER_RECIPIENTS:
            return {
                ...state,
                loaderRecipients: true,
            };
        default:
            return state;
    }
}
