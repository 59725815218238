import {
    FETCH_CONTENT_SELECTOR_TYPES,
    LOADER_FETCH_CONTENT_SELECTOR_TYPES,
} from "../actions/types";

const initialState = {
    selectorTypes: {},
    loaderContentSelectorTypesList: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTENT_SELECTOR_TYPES:
            return {
                ...state,
                selectorTypes: action.payload,
                loaderContentSelectorTypesList: false,
            };
        case LOADER_FETCH_CONTENT_SELECTOR_TYPES:
            return {
                ...state,
                loaderContentSelectorTypesList: true,
            };
        default:
            return state;
    }
}
