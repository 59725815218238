import {
    FETCH_SECTIONS,
    FETCH_SECTION,
    CREATE_SECTION,
    EDIT_SECTION,
    DELETE_SECTION,
    CLEAN_SECTION,
    FETCH_SECTION_TO_CONTENT_REFS,
    CREATE_SECTION_TO_CONTENT_REFS,
    CLEAN_SECTION_TO_CONTENT_REFS,
    LOADER_SECTIONS_LIST,
    LOADER_SECTIONS_FORM,
    LOADER_SECTION_TO_CONTENT_REFS,
    LOADER_CREATE_SECTION_TO_CONTENT_REFS,
} from "../actions/types";

const initialState = {
    sections: {},
    section: {},
    refs: {},
    loaderSectionsList: false,
    loaderSectionForm: false,
    loaderSectionToContentRefs: false,
    loaderCreateSectionToContentRefs: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SECTIONS: {
            return {
                ...state,
                sections: action.payload,
                loaderSectionsList: false,
            };
        }
        case FETCH_SECTION: {
            return {
                ...state,
                section: action.payload,
                loaderSectionForm: false,
            };
        }
        case CREATE_SECTION: {
            return {
                ...state,
                section: action.payload,
                loaderSectionForm: false,
            };
        }
        case EDIT_SECTION: {
            return {
                ...state,
                section: action.payload,
                loaderSectionForm: false,
            };
        }
        case DELETE_SECTION: {
            return {
                ...state,
                section: action.payload,
                loaderSectionsList: false,
            };
        }
        case FETCH_SECTION_TO_CONTENT_REFS:
            return {
                ...state,
                refs: action.payload,
                loaderSectionToContentRefs: false,
            };
        case CREATE_SECTION_TO_CONTENT_REFS:
            return {
                ...state,
                refs: action.payload,
                loaderCreateSectionToContentRefs: false,
            };
        case CLEAN_SECTION_TO_CONTENT_REFS:
            return {
                ...state,
                refs: initialState.refs,
                loaderSectionToContentRefs: false,
            };
        case CLEAN_SECTION: {
            return {
                ...state,
                section: initialState.section,
            };
        }
        case LOADER_SECTIONS_LIST:
            return {
                ...state,
                loaderSectionsList: true,
            };
        case LOADER_SECTIONS_FORM:
            return {
                ...state,
                loaderSectionForm: true,
            };
        case LOADER_SECTION_TO_CONTENT_REFS:
            return {
                ...state,
                loaderSectionToContentRefs: true,
            };
        case LOADER_CREATE_SECTION_TO_CONTENT_REFS:
            return {
                ...state,
                loaderCreateSectionToContentRefs: true,
            };
        default: {
            return state;
        }
    }
}
