import {
    FETCH_OUTPUT_CONFIGS,
    FETCH_OUTPUT_TYPES,
    CREATE_OUTPUT_CONFIG,
    FETCH_OUTPUT_CONFIG,
    DELETE_OUTPUT_CONFIG,
    DELETE_OUTPUT_CONFIGS,
    EDIT_OUTPUT_CONFIG,
    LOADER_OUPUT_CONFIG_LIST,
    LOADER_OUPUT_CONFIG_FORM,
} from "../actions/types";

const initialState = {
    configs: {},
    newOutputConfig: null,
    outputConfig: null,
    deletedOutputConfig: null,
    deletedMultipleOutputConfigs: null,
    editedOutputConfig: null,
    loaderOutputConfigList: false,
    loaderOutputConfigForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_OUTPUT_CONFIGS:
            return {
                ...state,
                configs: action.payload,
                loaderOutputConfigList: false,
            };
        case FETCH_OUTPUT_TYPES:
            return {
                ...state,
                types: action.payload,
            };
        case CREATE_OUTPUT_CONFIG:
            return {
                ...state,
                newOutputConfig: action.payload,
                loaderOutputConfigForm: false,
            };
        case EDIT_OUTPUT_CONFIG:
            return {
                ...state,
                editedOutputConfig: action.payload,
                loaderOutputConfigForm: false,
            };
        case FETCH_OUTPUT_CONFIG:
            return {
                ...state,
                outputConfig: action.payload,
                loaderOutputConfigForm: false,
            };
        case DELETE_OUTPUT_CONFIG:
            return {
                ...state,
                deletedOutputConfig: action.payload,
                loaderOutputConfigList: false,
            };
        case DELETE_OUTPUT_CONFIGS:
            return {
                ...state,
                deletedMultipleOutputConfigs: action.payload,
                loaderOutputConfigList: false,
            };
        case LOADER_OUPUT_CONFIG_LIST:
            return {
                ...state,
                loaderOutputConfigList: true,
            };
        case LOADER_OUPUT_CONFIG_FORM:
            return {
                ...state,
                loaderOutputConfigForm: true,
            };
        default:
            return state;
    }
}
