export const LOCALE_SET = "LOCALE_SET";
export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
export const FETCH_POST = "FETCH_POST";
export const NEW_POST = "NEW_POST";
export const SET_STATUS = "SET_STATUS";
export const REMOVE_STATUS = "REMOVE_STATUS";
export const SAVE_FORM = "SAVE_FORM";
export const UNSAFE_FORM = "UNSAFE_FORM";
export const SET_FORM_INPUT = "SET_FORM_INPUT";
export const GET_FORM_INPUTS = "GET_FORM_INPUTS";
export const INITIAL_FORM_INPUTS = "INITIAL_FORM_INPUTS";
export const INIT_FORM_VALIDATION = "INIT_FORM_VALIDATION";
export const UPDATE_FORM_VALIDATION = "UPDATE_FORM_VALIDATION";
export const SET_TOASTER_FUNCTION = "SET_TOASTER_FUNCTION";

export const SET_LOADER_VALUE = "SET_LOADER_VALUE";

export const FETCH_WORKSPACES = "FETCH_WORKSPACES";
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const GET_CURRENT_WORKSPACE = "GET_CURRENT_WORKSPACE";
export const FETCH_WORKSPACE = "FETCH_WORKSPACE";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const EDIT_WORKSPACE = "EDIT_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const DELETE_WORKSPACES = "DELETE_WORKSPACES";
export const CLEAR_WORKSPACE_FORM = "CLEAR_WORKSPACE_FORM";

export const FETCH_RECIPIENTS = "FETCH_RECIPIENTS";
export const LOADER_RECIPIENTS = "LOADER_RECIPIENTS";
export const FETCH_SENDOUT_RECIPIENTS = "FETCH_SENDOUT_RECIPIENTS";
export const CLEAN_SENDOUT_RECIPIENTS = "CLEAN_SENDOUT_RECIPIENTS";
export const FETCH_RECIPIENT = "FETCH_RECIPIENT";
export const CREATE_RECIPIENT = "CREATE_RECIPIENT";
export const DELETE_RECIPIENT = "DELETE_RECIPIENT";
export const DELETE_RECIPIENTS = "DELETE_RECIPIENTS";
export const DELETE_ALL_RECIPIENTS = "DELETE_ALL_RECIPIENTS";
export const UPDATE_RECIPIENT = "UPDATE_RECIPIENT";
export const CLEAN_IMPORTED_RECIPIENTS = "CLEAN_IMPORTED_RECIPIENTS";

export const REMOVE_DELETED_RECIPIENT = "REMOVE_DELETED_RECIPIENT";
export const SET_IMPORTED_RECIPIENT_FILE = "SET_IMPORTED_RECIPIENT_FILE";
export const REMOVE_IMPORTED_RECIPIENT_FILE = "REMOVE_IMPORTED_RECIPIENT_FILE";
export const UPLOAD_RECIPIENTS = "UPLOAD_RECIPIENTS";

export const FETCH_USERS = "FETCH_USERS";
export const GET_PROFILE = "GET_PROFILE";
export const SELECT_USER = "SELECT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const FETCH_USER = "FETCH_USER";
export const CREATE_USER = "CREATE_USER";
export const REGISTER_USER = "REGISTER_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_PASSWORD_STATUS = "CHANGE_PASSWORD_STATUS";
export const CLEAN_USER_FORM = "CLEAN_USER_FORM";

export const FETCH_SENDOUTS = "FETCH_SENDOUTS";
export const DELETE_SENDOUT = "DELETE_SENDOUT";
export const DELETE_SENDOUTS = "DELETE_SENDOUTS";
export const CREATE_SENDOUT = "CREATE_SENDOUT";
export const FETCH_SENDOUT = "FETCH_SENDOUT";
export const EDIT_SENDOUT = "EDIT_SENDOUT";
export const FETCH_OUTPUT_CONFIGS = "FETCH_OUTPUT_CONFIGS";
export const SET_SELECTED_RECIPIENTS = "SET_SELECTED_RECIPIENTS";
export const DELETE_ALL_SENDOUTS = "DELETE_ALL_SENDOUTS";
export const CLEAN_UP_CREATED_SENDOUT = "CLEAN_UP_CREATED_SENDOUT";
export const CLEAN_SENDOUT = "CLEAN_SENDOUT";
export const LOADER_SENDOUTS_LIST = "LOADER_SENDOUTS_LIST";
export const LOADER_FETCH_SENDOUT = "LOADER_FETCH_SENDOUT";
export const LOADER_GENERATE_LAYOUT = "LOADER_GENERATE_LAYOUT";
export const LOADER_SENDOUT_FORM = "LOADER_SENDOUT_FORM";
export const LOADER_FETCH_SENDOUT_RECIPIENTS =
    "LOADER_FETCH_SENDOUT_RECIPIENTS";
export const LOADER_JOBS_FETCH = "LOADER_JOBS_FETCH";
export const LOADER_STYLING_TEMPLATES_LIST = "LOADER_STYLING_TEMPLATES_LIST";
export const FETCH_STYLING_TEMPLATES = "FETCH_STYLING_TEMPLATES";
export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGNS = "DELETE_CAMPAIGNS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const DELETE_ALL_CAMPAIGNS = "DELETE_ALL_CAMPAIGNS";
export const CLEAN_UP_CREATED_CAMPAIGN = "CLEAN_UP_CREATED_CAMPAIGN";
export const CLEAN_CAMPAIGN = "CLEAN_CAMPAIGN";
export const LOADER_CAMPAIGNS_LIST = "LOADER_CAMPAIGNS_LIST";
export const LOADER_FETCH_CAMPAIGN = "LOADER_FETCH_CAMPAIGN";
export const LOADER_CAMPAIGN_FORM = "LOADER_CAMPAIGN_FORM";
export const LOADER_FETCH_CAMPAIGN_RECIPIENTS =
    "LOADER_FETCH_CAMPAIGN_RECIPIENTS";

export const CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS";
export const CLEAR_SENDOUTS = "CLEAR_SENDOUTS";
export const FETCH_CHAPTERS = "FETCH_CHAPTERS";
export const DELETE_CHAPTER = "DELETE_CHAPTER";
export const CREATE_CHAPTER = "CREATE_CHAPTER";
export const DELETE_CHAPTERS = "DELETE_CHAPTERS";
export const FETCH_CHAPTER = "FETCH_CHAPTER";
export const EDIT_CHAPTER = "EDIT_CHAPTER";
export const IMPORT_CHAPTERS = "IMPORT_CHAPTERS";
export const CLEAN_UP_IMPORTED_CHAPTERS = "CLEAN_UP_IMPORTED_CHAPTERS";
export const CLEAN_UP_CREATED_CHAPTERS = "CLEAN_UP_CREATED_CHAPTERS";
export const DELETE_ALL_SENDOUTS_PAGES = "DELETE_ALL_SENDOUTS_PAGES";
export const SET_IMPORTED_CHAPTERS_FILE = "SET_IMPORTED_CHAPTERS_FILE";
export const REMOVE_IMPORTED_CHAPTERS_FILE = "REMOVE_IMPORTED_CHAPTERS_FILE";
export const CLEAN_CHAPTER = "CLEAN_CHAPTER";
export const CLEAN_CHAPTERS = "CLEAN_CHAPTERS";
export const LOADER_CHAPTERS_LIST = "LOADER_CHAPTERS_LIST";
export const LOADER_CHAPTER_FORM = "LOADER_CHAPTER_FORM";

export const FETCH_SECTIONS = "FETCH_SECTIONS";
export const DELETE_SECTION = "DELETE_SECTION";
export const DELETE_SECTIONS = "DELETE_SECTIONS";
export const SET_SECTION_FORM_OPEN = "SET_SECTION_FORM_OPEN";
export const CREATE_SECTION = "CREATE_SECTION";
export const FETCH_SECTION = "FETCH_SECTION";
export const EDIT_SECTION = "EDIT_SECTION";
export const CLEAN_SECTION = "CLEAN_SECTION";
export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const CREATE_LAYOUT = "CREATE_LAYOUT";
export const CLEAN_LAYOUT = "CLEAN_LAYOUT";
export const DELETE_ALL_SECTIONS = "DELETE_ALL_SECTIONS";
export const TOGGLE_CONTENT_REF_FORM_MODAL = "TOGGLE_CONTENT_REF_FORM_MODAL";
export const UPDATE_SECTION_TO_CONTENT = "UPDATE_SECTION_TO_CONTENT";
export const MAP_CONTENT_TO_SECTION = "MAP_CONTENT_TO_SECTION";
export const LOADER_SECTIONS_LIST = "LOADER_SECTIONS_LIST";
export const LOADER_SECTIONS_FORM = "LOADER_SECTIONS_FORM";
export const LOADER_SECTION_TO_CONTENT_REFS = "LOADER_SECTION_TO_CONTENT_REFS";
export const LOADER_CREATE_SECTION_TO_CONTENT_REFS =
    "LOADER_CREATE_SECTION_TO_CONTENT_REFS";

export const CLEAR_JOBS = "CLEAR_JOBS";
export const CLEAR_JOB = "CLEAR_JOB";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOB = "FETCH_JOB";
export const FETCH_CHILD_JOB = "FETCH_CHILD_JOB";
export const FETCH_JOB_CHILDREN = "FETCH_JOB_CHILDREN";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOBS = "DELETE_JOBS";
export const FETCH_CHILD_JOBS = "FETCH_CHILD_JOBS";
export const CLEAN_CHILD_JOBS = "CLEAN_CHILD_JOBS";
export const CLEAN_CHILD_JOB = "CLEAN_CHILD_JOB";
export const DELETE_ALL_JOBS = "DELETE_ALL_JOBS";
export const REMOVE_JOB = "REMOVE_JOB";
export const FETCH_JOB_FILES = "FETCH_JOB_FILES";
export const DOWNLOAD_JOB_FILE = "DOWNLOAD_JOB_FILE";
export const FETCH_JOB_RESULTS = "FETCH_JOB_RESULTS";
export const FETCH_CHILD_JOB_RESULTS = "FETCH_CHILD_JOB_RESULTS";
export const JOB_PROGRESS = "JOB_PROGESSS";
export const LOADER_JOBS_LIST = "LOADER_JOBS_LIST";
export const LOADER_CHILD_JOBS_LIST = "LOADER_CHILD_JOBS_LIST";
export const LOADER_JOB_DETAILS = "LOADER_JOB_DETAILS";

export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const SET_SELECT = "SET_SELECT";
export const REMOVE_SELECT = "REMOVE_SELECT";
export const SET_REMOVE_SELECT = "SET_REMOVE_SELECT";
export const SELECT_ALL = "SELECT_ALL";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const GET_OUTPUT_CONFIGS = "GET_OUTPUT_CONFIGS";

export const IMPORT_CONTENT_SELECTOR = "IMPORT_CONTENT_SELECTOR";
export const SET_IMPORTED_CONTENT_SELECTOR_FILE =
    "SET_IMPORTED_CONTENT_SELECTOR_FILE";
export const REMOVE_IMPORTED_CONTENT_SELECTOR_FILE =
    "REMOVE_IMPORTED_CONTENT_SELECTOR_FILE";
export const CLEAN_CONTENT_CONNECTOR = "CLEAN_CONTENT_CONNECTOR";
export const FETCH_CONTENT_SELECTOR_CONFIGS = "FETCH_CONTENT_SELECTOR_CONFIGS";
export const FETCH_CONTENT_SELECTOR_TYPES = "FETCH_CONTENT_SELECTOR_TYPES";
export const FETCH_CONTENT_SELECTOR_CONFIG = "FETCH_CONTENT_SELECTOR_CONFIG";
export const DELETE_CONTENT_SELECTOR_CONFIG = "DELETE_CONTENT_SELECTOR_CONFIG";
export const DELETE_CONTENT_SELECTOR_CONFIGS =
    "DELETE_CONTENT_SELECTOR_CONFIGS";
export const DELETE_ALL_CONTENT_SELECTOR_CONFIGS =
    "DELETE_ALL_CONTENT_SELECTOR_CONFIGS";
export const LOADER_FETCH_CONTENT_SELECTOR_TYPES =
    "LOADER_FETCH_CONTENT_SELECTOR_TYPES";

export const CREATE_CONTENT_CONNECTOR_CONFIGS =
    "CREATE_CONTENT_CONNECTOR_CONFIGS";
export const FETCH_CONTENT_CONNECTOR_CONFIGS =
    "FETCH_CONTENT_CONNECTOR_CONFIGS";
export const FETCH_CONTENT_CONNECTOR_CONFIG = "FETCH_CONTENT_CONNECTOR_CONFIG";
export const DELETE_CONTENT_CONNECTOR_CONFIG =
    "DELETE_CONTENT_CONNECTOR_CONFIG";
export const DELETE_CONTENT_CONNECTOR_CONFIGS =
    "DELETE_CONTENT_CONNECTOR_CONFIGS";
export const DELETE_ALL_CONTENT_CONNECTOR_CONFIGS =
    "DELETE_ALL_CONTENT_CONNECTOR_CONFIGS";
export const FETCH_CONTENT_CONNECTOR_TYPES = "FETCH_CONTENT_CONNECTOR_TYPES";
export const EDIT_CONTENT_CONNECTOR_CONFIG = "EDIT_CONTENT_CONNECTOR_CONFIG";
export const REFRESH_CONTENT_CONNECTOR_CONTENT_TYPES =
    "REFRESH_CONTENT_CONNECTOR_CONTENT_TYPES";
export const RESET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM =
    "RESET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM";
export const LOADER_CCCTYPE_LIST = "LOADER_CCCTYPE_LIST";
export const LOADER_CCCTYPE_FETCH = "LOADER_CCCTYPE_FETCH";
export const LOADER_CCCTYPE_CONFIG = "LOADER_CCCTYPE_CONFIG";
export const LOADER_CCCTYPE_FORM = "LOADER_CCCTYPE_FORM";

export const FETCH_CONTENT_CONNECTOR_CONTENTS =
    "FETCH_CONTENT_CONNECTOR_CONTENTS";
export const EDIT_CONTENT_CONNECTOR_CONTENTS =
    "DELETE_CONTENT_CONNECTOR_CONTENTS";
export const CLEAN_CONTENT_CONNECTOR_CONTENTS =
    "CLEAN_CONTENT_CONNECTOR_CONTENTS";
export const FETCH_CONTENT_CONNECTOR_CONTENT =
    "FETCH_CONTENT_CONNECTOR_CONTENT";
export const CLEAN_CONTENT_CONNECTOR_CONTENT =
    "CLEAN_CONTENT_CONNECTOR_CONTENT";
export const CONTENT_CONNECTOR_INITIALISATION =
    "CONTENT_CONNECTOR_INITIALISATION";
export const CONTENT_CONNECTOR_AUTH_ERROR = "CONTENT_CONNECTOR_AUTH_ERROR";
export const LOADER_CONTENT_CONNECTOR_LIST = "LOADER_CONTENT_CONNECTOR_LIST";
export const LOADER_CONTENT_CONNECTOR_TYPES_FETCH =
    "LOADER_CONTENT_CONNECTOR_TYPES_FETCH";
export const LOADER_CONTENT_CONNECTOR_INITIALISATION =
    "LOADER_CONTENT_CONNECTOR_INITIALISATION";
export const LOADER_CONTENT_CONNECTOR_CONTENTS_LIST =
    "LOADER_CONTENT_CONNECTOR_CONTENTS_LIST";
export const LOADER_FETCH_CONTENT_CONNECTOR_CONTENT =
    "LOADER_FETCH_CONTENT_CONNECTOR_CONTENT";

export const FETCH_CONTENT_CONNECTOR_CONTENT_TYPES =
    "FETCH_CONTENT_CONNECTOR_CONTENT_TYPES";
export const FETCH_CONTENT_CONNECTOR_CONTENT_TYPE =
    "FETCH_CONTENT_CONNECTOR_CONTENT_TYPE";
export const EDIT_CONTENT_CONNECTOR_CONTENT_TYPE =
    "EDIT_CONTENT_CONNECTOR_CONTENT_TYPE";
export const SET_READY = "SET_READY";
export const SET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM_DATA =
    "SET_CONTENT_CONNECTOR_CONTENT_TYPE_FORM_DATA";
export const SET_CONTENT_CONNECTOR_CONTENT_TYPE__FIELD_FORM_DATA =
    "SET_CONTENT_CONNECTOR_CONTENT_TYPE__FIELD_FORM_DATA";
export const CLEAN_CONTENT_CONNECTOR_CONTENT_TYPES =
    "CLEAN_CONTENT_CONNECTOR_CONTENT_TYPES";
export const CLEAN_CONTENT_CONNECTOR_CONTENT_TYPE =
    "CLEAN_CONTENT_CONNECTOR_CONTENT_TYPE";
export const SET_LABEL_FIELD = "SET_LABEL_FIELD";
export const REFRESH_CONTENT_CONNECTOR_CONTENT_TYPE =
    "REFRESH_CONTENT_CONNECTOR_CONTENT_TYPE";

export const SET_WIZARD_STEP = "SET_WIZARD_STEP";
export const SET_WIZARD_STEPS = "SET_WIZARD_STEPS";
export const CLEAN_WIZARD_STEP = "CLEAN_WIZARD_STEP";
export const CLEAN_WIZARD_STEPS = "CLEAN_WIZARD_STEPS";

export const DROP_ITEM = "DROP_ITEM";
export const DELETE_CBM_CELL = "DELETE_CBM_CELL";
export const DRAG_STATUS = "DRAG_STATUS";

export const FETCH_CONTENT_BUILDER_MODELS = "FETCH_CONTENT_BUILDER_MODELS";
export const FETCH_CBM_LIST = "FETCH_CBM_LIST";
export const FETCH_CONTENT_TYPES = "FETCH_CONTENT_TYPES";
export const SET_CBM_TREE = "SET_CBM_TREE";
export const CREATE_CBM = "CREATE_CBM";
export const DELETE_CBM = "DELETE_CBM";
export const NOTIFY_CONTENTTYPE_CHANGE = "NOTIFY_CONTENTTYPE_CHANGE";
export const FETCH_CBM = "FETCH_CBM";
export const EDIT_CBM = "EDIT_CBM";
export const DELETE_CBM_MULTIPLE = "DELETE_CBM_MULTIPLE";
export const DELETE_CBM_ALL = "DELETE_CBM_ALL";
export const TOGGLE_FLOATINGS = "TOGGLE_FLOATINGS";
export const RESET_FLOATING_DATA = "RESET_FLOATING_DATA";
export const SAVE_FLOATING_DATA = "SAVE_FLOATING_DATA";
export const UPDATE_CBM_INSTANCE = "UPDATE_CBM_INSTANCE";
export const RESET_CBM_INSTANCES = "RESET_CBM_INSTANCES";
export const CLEAR_CBM = "CLEAR_CBM";
export const CBM_FULLSCREEN_STATUS = "CBM_FULLSCREEN_STATUS";
export const LOADER_CBM_LIST = "LOADER_CBM_LIST";
export const LOADER_CBM_FORM = "LOADER_CBM_FORM";
export const LOADER_CBM_FORM_CONTENT_TYPES = "LOADER_CBM_FORM_CONTENT_TYPES";

export const SET_CSS = "SET_CSS";
export const CLEAN_CSS = "CLEAN_CSS";
export const POST_CSS = "POST_CSS";
export const FETCH_CSS_FILES = "FETCH_CSS_FILES";
export const GET_CSS_FILE = "GET_CSS_FILE";
export const DELTE_CSS_FILES = "DELETE_CSS_FILES";
export const DELTE_CSS_FILE = "DELETE_CSS_FILE";
export const DELTE_ALL_CSS_FILES = "DELETE_ALL_CSS_FILES";
export const PATCH_CSS_FILE = "PATCH_CSS_FILE";
export const LOADER_CSS_FILES_LIST = "LOADER_CSS_FILES_LIST";
export const LOADER_CSS_FORM = "LOADER_CSS_FORM";

export const FETCH_SECTION_TO_CONTENT_REFS = "FETCH_SECTION_TO_CONTENT_REFS";
export const CLEAN_SECTION_TO_CONTENT_REFS = "CLEAN_SECTION_TO_CONTENT_REFS";

export const CREATE_SECTION_TO_CONTENT_REF = "CREATE_SECTION_TO_CONTENT_REF";
export const CREATE_SECTION_TO_CONTENT_REFS = "CREATE_SECTION_TO_CONTENT_REFS";
export const DELETE_ALL_SECTION_TO_CONTENT_REFS =
    "DELETE_ALL_SECTION_TO_CONTENT_REFS";
export const EDIT_SECTION_TO_CONTENT_REF = "EDIT_SECTION_TO_CONTENT_REF";
export const UPDATE_MULTIPLE_SECTION_TO_CONTENT_REFS =
    "UPDATE_MULTIPLE_SECTION_TO_CONTENT_REFS";
export const FETCH_CONTENT_REFS = "FETCH_CONTENT_REFS";
export const FETCH_CONTENT_REF = "FETCH_CONTENT_REF";
export const EDIT_CONTENT_REFS = "EDIT_CONTENT_REFS";
export const EDIT_CONTENT_REF = "EDIT_CONTENT_REF";
export const CLEAN_CONTENT_REF = "CLEAN_CONTENT_REF";
export const DELETE_CONTENT_REFS = "DELETE_CONTENT_REFS";
export const CLEAN_CONTENT_REFS = "CLEAN_CONTENT_REFS";
export const LOADER_CONTENT_REFS_LIST = "LOADER_CONTENT_REFS_LIST";
export const LOADER_CONTENT_REFS_FORM = "LOADER_CONTENT_REFS_FORM";

export const FETCH_FIX_POSITIONS = "FETCH_FIX_POSITIONS";
export const CLEAN_FIX_POSITIONS = "CLEAN_FIX_POSITIONS";

export const FETCH_OUTPUT_TYPES = "FETCH_OUTPUT_TYPES";
export const CREATE_OUTPUT_CONFIG = "CREATE_OUTPUT_CONFIG";
export const FETCH_OUTPUT_CONFIG = "FETCH_OUTPUT_CONFIG";
export const EDIT_OUTPUT_CONFIG = "EDIT_OUTPUT_CONFIG";
export const DELETE_OUTPUT_CONFIG = "DELETE_OUTPUT_CONFIG";
export const DELETE_OUTPUT_CONFIGS = "DELETE_OUTPUT_CONFIGS";

export const textBlocks = {
    FETCH_TEXT_BLOCKS: "FETCH_TEXT_BLOCKS",
    DELETE_TEXT_BLOCKS: "DELETE_TEXT_BLOCKS",
    EDIT_TEXT_BLOCKS: "EDIT_TEXT_BLOCKS",
    CREATE_TEXT_BLOCKS: "CREATE_TEXT_BLOCKS",
    CLEAN_TEXT_BLOCKS: "CLEAN_TEXT_BLOCKS",
    LOADER_TEXT_BLOCKS_LIST: "LOADER_TEXT_BLOCKS_LIST",
    LOADER_TEXT_BLOCKS_FORM: "LOADER_TEXT_BLOCKS_FORM",
};
export const textBlock = {
    FETCH_TEXT_BLOCK: "FETCH_TEXT_BLOCK",
    DELETE_TEXT_BLOCK: "DELETE_TEXT_BLOCK",
    EDIT_TEXT_BLOCK: "EDIT_TEXT_BLOCK",
    CREATE_TEXT_BLOCK: "CREATE_TEXT_BLOCK",
    CLEAN_TEXT_BLOCK: "CLEAN_TEXT_BLOCK",
};

export const FETCH_TEXT_BLOCK = "FETCH_TEXT_BLOCK";
export const DELETE_TEXT_BLOCK = "DELETE_TEXT_BLOCK";
export const EDIT_TEXT_BLOCK = "EDIT_TEXT_BLOCK";
export const CREATE_TEXT_BLOCK = "CREATE_TEXT_BLOCK";
export const CLEAN_TEXT_BLOCK = "CLEAN_TEXT_BLOCK";
export const LOADER_OUPUT_CONFIG_LIST = "LOADER_OUPUT_CONFIG_LIST";
export const LOADER_OUPUT_CONFIG_FORM = "LOADER_OUPUT_CONFIG_FORM";

export const FETCH_LAYOUT_GENERATOR_CONFIG_LIST =
    "FETCH_LAYOUT_GENERATOR_CONFIG_LIST";
export const FETCH_LAYOUT_GENERATOR_CONFIG = "FETCH_LAYOUT_GENERATOR_CONFIG";
export const FETCH_LAYOUT_GENERATOR_TYPES = "FETCH_LAYOUT_GENERATOR_TYPES";
export const CREATE_LAYOUT_GENERATOR_CONFIG = "CREATE_LAYOUT_GENERATOR_CONFIG";
export const EDIT_LAYOUT_GENERATOR_CONFIG = "EDIT_LAYOUT_GENERATOR_CONFIG";
export const DELETE_LAYOUT_GENERATOR_CONFIG = "DELETE_LAYOUT_GENERATOR_CONFIG";
export const DELETE_LAYOUT_GENERATOR_CONFIGS =
    "DELETE_LAYOUT_GENERATOR_CONFIGS";
export const LOADER_LAYOUT_GENERATOR_CONFIG_LIST =
    "LOADER_LAYOUT_GENERATOR_CONFIG_LIST";
export const LOADER_LAYOUT_GENERATOR_CONFIG_FORM =
    "LOADER_LAYOUT_GENERATOR_CONFIG_FORM";

export const FETCH_INDEX_TABLE_LIST = "FETCH_INDEX_TABLE_LIST";
export const FETCH_INDEX_TABLE = "FETCH_INDEX_TABLE";
export const CREATE_INDEX_TABLE = "CREATE_INDEX_TABLE";
export const EDIT_INDEX_TABLE = "EDIT_INDEX_TABLE";
export const DELETE_INDEX_TABLE = "DELETE_INDEX_TABLE";
export const DELETE_INDEX_TABLES = "DELETE_INDEX_TABLES";
export const LOADER_INDEX_TABLE_LIST = "LOADER_INDEX_TABLE_LIST";
export const LOADER_INDEX_TABLE_FORM = "LOADER_INDEX_TABLE_FORM";
export const FETCH_ALL_CONTENT_CONNECTOR_CONTENT_TYPES =
    "FETCH_ALL_CONTENT_CONNECTOR_CONTENT_TYPES";

export const FETCH_RECIPIENT_CONNECTOR_CONFIGS =
    "FETCH_RECIPIENT_CONNECTOR_CONFIGS";
export const LOADER_RECIPIENT_CONNECTOR_LIST =
    "LOADER_RECIPIENT_CONNECTOR_LIST";
export const RECIPIENT_CONNECTOR_AUTH_ERROR = "RECIPIENT_CONNECTOR_AUTH_ERROR";
export const RECIPIENT_CONNECTOR_INITIALISATION =
    "RECIPIENT_CONNECTOR_INITIALISATION";
export const LOADER_RECIPIENT_CONNECTOR_INITIALISATION =
    "LOADER_RECIPIENT_CONNECTOR_INITIALISATION";
export const FETCH_RECIPIENT_CONNECTOR_CONFIG =
    "FETCH_RECIPIENT_CONNECTOR_CONFIG";
export const DELETE_RECIPIENT_CONNECTOR_CONFIG =
    "DELETE_CRECIPIENTCONNECTOR_CONFIG";
export const DELETE_RECIPIENT_CONNECTOR_CONFIGS =
    "DELETE_RECIPIENT_CONNECTOR_CONFIGS";
export const DELETE_ALL_RECIPIENT_CONNECTOR_CONFIGS =
    "DELETE_ALL_RECIPIENT_CONNECTOR_CONFIGS";
export const FETCH_RECIPIENT_CONNECTOR_TYPES =
    "FETCH_RECIPIENT_CONNECTOR_TYPES";
export const LOADER_RECIPIENT_CONNECTOR_TYPES_FETCH =
    "LOADER_RECIPIENT_CONNECTOR_TYPES_FETCH";
export const EDIT_RECIPIENT_CONNECTOR_CONFIG =
    "EDIT_RECIPIENT_CONNECTOR_CONFIG";

export const LOADER_RCCTYPE_LIST = "LOADER_RCCTYPE_LIST";
export const LOADER_RCCTYPE_FETCH = "LOADER_RCCTYPE_FETCH";
export const LOADER_RCCTYPE_CONFIG = "LOADER_RCCTYPE_FETCH";
export const LOADER_RCCTYPE_FORM = "LOADER_RCCTYPE_FORM";
export const FETCH_RECIPIENT_CONNECTOR_RECIPIENT_TYPES =
    "FETCH_RECIPIENT_CONNECTOR_RECIPIENT_TYPES";
export const LOADER_RCRECIPIENT_TYPE_LIST = "LOADER_RCRECIPIENT_TYPE_LIST";

export const FETCH_TEMPLATE_LIST = "FETCH_TEMPLATE_LIST";
export const DELETE_TEMPLATES = "DELETE_TEMPLATES";
export const LOADER_TEMPLATE_LIST = "LOADER_TEMPLATE_LIST";
export const LOADER_TEMPLATE_FORM = "LOADER_TEMPLATE_FORM";
export const CLEAN_TEMPLATE = "CLEAN_TEMPLATE";

export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const SEARCH_TEMPLATES = "SEARCH_TEMPLATES";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAILURE = "CREATE_TEMPLATE_FAILURE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

export const FETCH_SHARED_TEMPLATES = "FETCH_SHARED_TEMPLATES";
export const FETCH_SHARED_TEMPLATES_SUCCESS = "FETCH_SHARED_TEMPLATES_SUCCESS";
export const FETCH_SHARED_TEMPLATES_FAILURE = "FETCH_SHARED_TEMPLATES_FAILURE";
export const CLEAN_SHARED_TEMPLATES = "CLEAN_SHARED_TEMPLATES";

export const FETCH_LOCAL_TEMPLATES = "FETCH_LOCAL_TEMPLATES";
export const FETCH_LOCAL_TEMPLATES_SUCCESS = "FETCH_LOCAL_TEMPLATES_SUCCESS";
export const FETCH_LOCAL_TEMPLATES_FAILURE = "FETCH_LOCAL_TEMPLATES_FAILURE";
export const CLEAN_LOCAL_TEMPLATES = "CLEAN_LOCAL_TEMPLATES";

export const CLEAN_PREVIEW_IMAGES_LENGTH = "CLEAN_PREVIEW_IMAGES_LENGTH";
export const FETCH_PREVIEW_IMAGES_LENGTH = "FETCH_PREVIEW_IMAGES_LENGTH";
export const FETCH_PREVIEW_IMAGES_LENGTH_SUCCESS =
    "FETCH_PREVIEW_IMAGES_LENGTH_SUCCESS";
export const FETCH_PREVIEW_IMAGES_LENGTH_FAILURE =
    "FETCH_PREVIEW_IMAGES_LENGTH_FAILURE";
export const CLEAN_PREVIEW_IMAGE = "CLEAN_PREVIEW_IMAGE";
export const FETCH_PREVIEW_IMAGE = "FETCH_PREVIEW_IMAGE";
export const FETCH_PREVIEW_IMAGE_SUCCESS = "FETCH_PREVIEW_IMAGE_SUCCESS";
export const FETCH_PREVIEW_IMAGE_FAILURE = "FETCH_PREVIEW_IMAGE_FAILURE";

export const FETCH_FONT_TYPES = "FETCH_FONT_TYPES";
export const FETCH_FONT_TYPES_SUCCESS = "FETCH_FONT_TYPES_SUCCESS";
export const FETCH_FONT_TYPES_FAILURE = "FETCH_FONT_TYPES_FAILURE";

export const FETCH_ASSETS = "FETCH_ASSETS";
export const CLEAN_ASSET = "CLEAN_ASSET";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_FAILURE = "FETCH_ASSETS_FAILURE";

export const FETCH_ASSET = "FETCH_ASSET_2";
export const FETCH_ASSET_SUCCESS = "FETCH_ASSET_SUCCESS";
export const FETCH_ASSET_FAILURE = "FETCH_ASSET_FAILURE";

export const CREATE_ASSET = "CREATE_ASSET_2";
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

export const DOWNLOAD_ASSET = "DOWNLOAD_ASSET";
export const DOWNLOAD_ASSET_SUCCESS = "DOWNLOAD_ASSET_SUCCESS";
export const DOWNLOAD_ASSET_FAILURE = "DOWNLOAD_ASSET_FAILURE";
