import {
    SET_WIZARD_STEP,
    SET_WIZARD_STEPS,
    CLEAN_WIZARD_STEP,
    CLEAN_WIZARD_STEPS,
} from "../actions/types";
const initialState = {
    step: {},
    steps: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_WIZARD_STEP: {
            return {
                ...state,
                step: action.payload,
            };
        }
        case SET_WIZARD_STEPS:
            return {
                ...state,
                steps: action.payload,
            };
        case CLEAN_WIZARD_STEP:
            return {
                ...state,
                step: initialState.step,
            };
        case CLEAN_WIZARD_STEPS:
            return {
                ...state,
                steps: [],
            };
        default:
            return state;
    }
}
