import {
    FETCH_RECIPIENT_CONNECTOR_RECIPIENT_TYPES,
    LOADER_RCRECIPIENT_TYPE_LIST,
} from "../actions/types";

const initialState = {
    recipientList: {},
    loaderRCRecipientTypes: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_RECIPIENT_CONNECTOR_RECIPIENT_TYPES:
            return {
                ...state,
                recipientList: action.payload,
                loaderRCRecipientTypes: false,
            };

        case LOADER_RCRECIPIENT_TYPE_LIST:
            return {
                ...state,
                loaderRCRecipientTypes: true,
            };
        default:
            return state;
    }
}
