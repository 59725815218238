/* eslint-disable default-case */
import produce from "immer";

import {
    FETCH_ASSETS,
    FETCH_ASSETS_SUCCESS,
    FETCH_ASSETS_FAILURE,
    FETCH_ASSET,
    FETCH_ASSET_SUCCESS,
    FETCH_ASSET_FAILURE,
    CREATE_ASSET,
    CREATE_ASSET_SUCCESS,
    CREATE_ASSET_FAILURE,
    CLEAN_ASSET,
    DOWNLOAD_ASSET,
    DOWNLOAD_ASSET_SUCCESS,
    DOWNLOAD_ASSET_FAILURE,
} from "../actions/types";

export const DEFAULT_ASSETS = {
    data: {},
    loading: false,
    error: {},
};

export const DEFAULT_ASSET = {
    data: {},
    loading: false,
    error: {},
};

export const DEFAULT_CONTENT_TYPES = {
    data: {},
    loading: false,
    error: {},
};

export const assetsManagerReducer = (
    state = { ...DEFAULT_ASSETS },
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case FETCH_ASSETS:
                draft.loading = true;
                break;

            case FETCH_ASSETS_SUCCESS || FETCH_ASSETS_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
        }
    });

export const assetManagerReducer = (
    state = { ...DEFAULT_ASSET },
    { type, payload = {}, error = {} }
) =>
    produce(state, (draft) => {
        switch (type) {
            case CREATE_ASSET:
                draft.loading = true;
                break;

            case CREATE_ASSET_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case CREATE_ASSET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case FETCH_ASSET:
                draft.loading = true;
                break;

            case FETCH_ASSET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;

            case FETCH_ASSET_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case DOWNLOAD_ASSET:
                draft.loading = true;
                break;
            case DOWNLOAD_ASSET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case DOWNLOAD_ASSET_FAILURE:
                draft.loading = false;
                draft.data = payload;
                draft.error = error;
                break;
            case CLEAN_ASSET:
                return {
                    ...DEFAULT_ASSET,
                };
        }
    });
