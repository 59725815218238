import {
    FETCH_CAMPAIGNS,
    CREATE_CAMPAIGN,
    EDIT_CAMPAIGN,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGNS,
    FETCH_CAMPAIGN,
    CLEAR_CAMPAIGNS,
    CREATE_LAYOUT,
    DELETE_ALL_CAMPAIGNS,
    CLEAN_LAYOUT,
    CLEAN_UP_CREATED_CAMPAIGN,
    CLEAN_CAMPAIGN,
    FETCH_JOBS,
    LOADER_CAMPAIGNS_LIST,
    LOADER_FETCH_CAMPAIGN,
    LOADER_GENERATE_LAYOUT,
    LOADER_CAMPAIGN_FORM,
    LOADER_JOBS_FETCH,
    FETCH_STYLING_TEMPLATES,
    LOADER_STYLING_TEMPLATES_LIST,
} from "../actions/types";
const initialState = {
    requestStatus: {},
    campaigns: {},
    campaign: {},
    editedCampaign: {},
    deletedCampaigns: [],
    deletedCampaign: {},
    deletedAllCampaigns: null,
    layoutJob: {},
    recipients: {},
    jobs: {},
    loaderCampaignsList: false,
    loaderCampaignFetch: false,
    loaderGenerateLayout: false,
    loaderCampaignForm: false,
    loaderCampaignrecipientsFetch: false,
    loaderJobsFetch: false,
    loaderStylingTemplates: false,
    stylingTemplates: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_CAMPAIGNS: {
            return { ...initialState };
        }
        case CLEAN_CAMPAIGN: {
            return {
                ...state,
                campaign: {},
            };
        }
        case FETCH_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload,
                loaderCampaignFetch: false,
            };
        case FETCH_CAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload,
                loaderCampaignsList: false,
            };
        case DELETE_CAMPAIGN:
            return {
                ...state,
                deletedCampaign: action.payload,
                loaderCampaignsList: false,
            };
        case DELETE_CAMPAIGNS:
            return {
                ...state,
                deletedCampaigns: action.payload,
                loaderCampaignsList: false,
            };

        case DELETE_ALL_CAMPAIGNS:
            return {
                ...state,
                deletedAllCampaigns: action.payload,
                loaderCampaignsList: false,
            };
        case CLEAN_UP_CREATED_CAMPAIGN:
            return {
                ...state,
                createdCampaign: {},
            };
        case CREATE_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload,
                loaderCampaignForm: false,
            };
        case EDIT_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload,
                loaderCampaignForm: false,
            };

        case CREATE_LAYOUT:
            return {
                ...state,
                layoutJob: action.payload,
                loaderGenerateLayout: false,
            };
        case CLEAN_LAYOUT:
            return {
                ...state,
                layoutJob: initialState.layoutJob,
            };
        case FETCH_JOBS:
            return {
                ...state,
                jobs: action.payload,
                loaderJobsFetch: false,
            };
        case LOADER_CAMPAIGNS_LIST:
            return {
                ...state,
                loaderCampaignsList: true,
            };
        case LOADER_FETCH_CAMPAIGN:
            return {
                ...state,
                loaderCampaignFetch: true,
            };
        case LOADER_GENERATE_LAYOUT:
            return {
                ...state,
                loaderGenerateLayout: true,
            };
        case LOADER_CAMPAIGN_FORM:
            return {
                ...state,
                loaderCampaignForm: true,
            };
        case LOADER_JOBS_FETCH:
            return {
                ...state,
                loaderJobsFetch: true,
            };
        case LOADER_STYLING_TEMPLATES_LIST:
            return {
                ...state,
                loaderStylingTemplates: true,
            };
        case FETCH_STYLING_TEMPLATES:
            return {
                ...state,
                stylingTemplates: action.payload,
                loaderStylingTemplates: false,
            };
        default:
            return state;
    }
}
