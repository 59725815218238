import {
    FETCH_INDEX_TABLE_LIST,
    FETCH_INDEX_TABLE,
    CREATE_INDEX_TABLE,
    EDIT_INDEX_TABLE,
    DELETE_INDEX_TABLE,
    DELETE_INDEX_TABLES,
    LOADER_INDEX_TABLE_FORM,
    LOADER_INDEX_TABLE_LIST,
    FETCH_ALL_CONTENT_CONNECTOR_CONTENT_TYPES,
} from "../actions/types";

const initialState = {
    indexTables: {},
    type: {},
    indexTable: null,
    newIndexTable: null,
    editedIndexTable: null,
    deletedIndexTable: null,
    deletedMultipleIndexTables: null,
    loaderIndexTableList: false,
    loaderIndexTableForm: false,
    contentTypes: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_INDEX_TABLE_LIST:
            return {
                ...state,
                indexTables: action.payload,
                loaderIndexTableList: false,
            };
        case FETCH_INDEX_TABLE:
            return {
                ...state,
                indexTable: action.payload,
                loaderIndexTableForm: false,
            };
        case CREATE_INDEX_TABLE:
            return {
                ...state,
                newIndexTable: action.payload,
                loaderIndexTableForm: false,
            };
        case EDIT_INDEX_TABLE:
            return {
                ...state,
                editedIndexTable: action.payload,
                loaderIndexTableForm: false,
            };
        case DELETE_INDEX_TABLE:
            return {
                ...state,
                deletedIndexTable: action.payload,
                loaderIndexTableList: false,
            };
        case DELETE_INDEX_TABLES:
            return {
                ...state,
                deletedMultipleIndexTables: action.payload,
                loaderIndexTableList: false,
            };
        case FETCH_ALL_CONTENT_CONNECTOR_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
                loaderIndexTableForm: false,
            };
        case LOADER_INDEX_TABLE_LIST:
            return {
                ...state,
                loaderIndexTableList: true,
            };
        case LOADER_INDEX_TABLE_FORM:
            return {
                ...state,
                loaderIndexTableForm: true,
            };
        default:
            return state;
    }
}
